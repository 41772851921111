import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledPanel = styled.div`
    background: white;   
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    &.overflow-hidden {
        overflow: hidden;
    }
    &.no-padding {
        padding: 0 0 0 0;
    }
    &.padding-top-only {
        padding: 20px 0 0 0;
    }
    &.is-notice {
        text-align: center;
        box-shadow: none;
        background-color: #e8e8e2;
    }
`;

class Panel extends PureComponent {
    render() {
        const { classNames, noPadding, paddingTop, children, notice } = this.props;
        let classes = classNames ? `${classNames} ` : '';
        if (noPadding) {
            classes += 'no-padding ';
         }
        if (paddingTop) {
           classes += 'padding-top-only ';
        }
        if (notice) {
            classes += 'is-notice ';
         }
        return (<StyledPanel className={ classes }>{children}</StyledPanel>);
    }
}
export default Panel;