import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledPanelSection = styled.div`
    border-bottom: 1px solid #efefef;
    padding: 20px 15px;
    &.no-border {
        border-bottom: none;
    }
`;

export class Panel extends PureComponent {
    render() {
        let classes = `${this.props.className}`;
        if(this.props.noBorder) {
           classes += ' no-border';
        };
        return (
            <StyledPanelSection className={ classes }>
                { this.props.title && <h2>{ this.props.title }</h2>}
                {this.props.children}
            </StyledPanelSection>
        );
    }
}
export default Panel;