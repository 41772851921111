import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledPanelTotals = styled.div`
    margin-top: 20px;
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
    .box {
        background: white;
        padding: 20px;
        min-width: 300px;
        background-color: white;
        border-radius: 3px;
        -webkit-box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
        box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);            
    }
    table {
        width: 100%;
    }
    .order__total {
        text-align: right;
        font-size: 24px;
    }
    small {
        font-size: 12px;
        display: block;
    }
`;

export class PanelTotals extends PureComponent {
    render() {
        let classes = `${this.props.className}`;
        if(this.props.noBorder) {
           classes += ' no-border';
        };
        return (
            <StyledPanelTotals className={ classes }>
                <div className="box">
                {this.props.children}
                </div>
            </StyledPanelTotals>
        );
    }
}
export default PanelTotals;