import { SET_TOKEN, UPDATE_CURRENT_USER, LOGOUT } from "../actions/types";

const initialState = {
    isAuthenticated: false,
    jwt: null,
    current_store: null,
    user: {}
}
export default (state = initialState, action = {}) => {
    switch(action.type) {
        case SET_TOKEN:
            return {
                ...state,
                jwt: action.token
            };
        case UPDATE_CURRENT_USER:
            return {
                 ...state,
                 ...action.payload
            }
        case LOGOUT:
            return initialState
        default: return state;
    }
}