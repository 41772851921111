import { FETCH_ORDERS,UPDATE_LOADING, FETCH_STATUSES, LOAD_ORDER_TO_DRAFT, LOAD_ORDER_TO_EDIT, NEW_ORDER, CLEAR_DRAFT_ORDER, UPDATE_DRAFT_FROM_ORDER, UPDATE_DRAFT_ORDER_PRODUCTS, UPDATE_DRAFT_ORDER, SET_EDIT_ORDER, LOGOUT } from '../actions/types';

const initialState = {
    items: {},
    item: {},
    search: [],
    page: 1,
    per_page: 20,
    total_items: 0,
    statuses: {},
    is_loading: false,
    edit: {
        shipping: {},
        billing: {},
        date_created: '',
        date_required_by: '',
        contact_details: '',
        purchase_order_number: '',
        invoice_number: '',
        cancelled_note: '',
        wbs_code: '',
        cost_centre: '',
        bp_code: '',
        dealer_code: '',
        reason: '',
        notes: '',
        note: '',
        order_approver: '',
        products: [],
        status: '',
        subtotal: 0,
        total: 0,
        total_tax: 0,
        meta_data: [],
        shipping_lines: []
    },
    draft: {
        shipping: {},
        billing: {},
        date_created: '',
        date_required_by: '',
        contact_details: '',
        purchase_order_number: '',
        invoice_number: '',
        cancelled_note: '',
        wbs_code: '',
        cost_centre: '',
        bp_code: '',
        dealer_code: '',
        reason: '',
        notes: '',
        note: '',
        order_approver: '',
        products: [],
        status: '',
        subtotal: 0,
        total: 0,
        total_tax: 0,
        meta_data: [],
        shipping_lines: []
    }
}
export default (state = initialState, action = {}) => {
    switch(action.type) {
        case UPDATE_LOADING: 
          return {
            ...state,
            is_loading: action.payload.is_loading
          }
        case 'REQUEST_ORDERS_PAGE':
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [action.payload.page]: {
                        ids: [],
                        fetching: true
                    }
                }
            }
        case 'RECEIVE_ORDERS_PAGE':
            return {
                ...state,
                is_loading: false,
                pagination: {
                    ...state.pagination,
                    [action.payload.page]: {
                        ids: action.payload.items.map(item => item.id),
                        fetching: false
                    }
                }
            }

        case FETCH_ORDERS:
            const items = { };
            
            action.payload.items.forEach(order => {
                const { 
                    id, 
                    meta_data,
                    payment_link,
                    line_items,
                    date_created,
                    status,
                    shipping_address,
                    billing,
                    shipping,
                    customer_note,
                    shipping_tax,
                    shipping_total,
                    shipping_lines,
                    subtotal,
                    total,
                    total_tax                    
                } = order;
                
                const mappedLineItems = line_items.map( item => {
                    if (item.product) {
                        item.stock_quantity = item.product.stock_quantity;
                        item.current_price = item.product.price;
                        delete item.product;
                    }
                    return item;
                });
                // console.log(order);
                const total_without_tax = parseFloat(total) - parseFloat(total_tax);
                const item = {
                    id,
                    line_items: mappedLineItems,
                    payment_link,
                    date_created,
                    status,
                    shipping_address, 
                    billing,
                    shipping,
                    customer_note,
                    shipping_tax,
                    shipping_total,
                    shipping_lines,
                    subtotal,
                    total,
                    total_tax,
                    total_without_tax,
                    cancelled_note: '',
                    purchase_order_number: '',
                    invoice_number: '',
                    wbs_code: '',
                    cost_centre: '',
                    date_required_by: '',
                    contact_details: '',
                    bp_code: '',
                    dealer_code: '',
                    reason: '',
                    notes: '',
                    order_approver: ''
                };
                
                if (meta_data){
                    meta_data.forEach( data => {
                        item[data.key] = data.value;
                    });
                }
                if (id !== undefined){
                    items[`${id}`] = item;
                }                
            });
            let hasNextPage = true;
            if (items.length === action.payload.total_items){
                hasNextPage = false;
            }
            
            return {
                ...state,
                is_loading: false,
                page: action.payload.page,
                per_page: action.payload.per_page,
                items,
                hasNextPage,
                total_items: action.payload.total_items,
                total_pages: action.payload.total_pages
            };
        case FETCH_STATUSES:
            return {
                ...state,
                statuses: action.payload
            };
        case UPDATE_DRAFT_ORDER:
            return {
                ...state,
                draft: {
                    ...state.draft,
                    ...action.payload
                }
            };
        case SET_EDIT_ORDER:
            return {
                ...state,
                edit: {
                    ...state.edit,
                    ...action.payload
                }
            };
        case CLEAR_DRAFT_ORDER:
            return {
                ...state,
                draft: {
                    ...initialState.draft
                }
            }
        case UPDATE_DRAFT_ORDER_PRODUCTS:
            const products = action.payload.products;
            const total = products.reduce((sum, product) => {
                return sum + (parseFloat(product.price) * parseInt(product.quantity));
            },0);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    products,
                    total: total
                }
            };
        case NEW_ORDER:
            state.items.unshift(action.payload);
            return {
                ...state,
                item: action.payload,
                draft: initialState.draft
            };
        case UPDATE_DRAFT_FROM_ORDER:
            const order_details = state.items[action.payload.order_id];
            let line_items = null;
            if(order_details){
                line_items = order_details.line_items.map(line_item => {
                    return line_item;
                });
            }
            const order_total = line_items.reduce((sum, product) => {
                return sum + (parseFloat(product.price) * parseFloat(product.quantity));
            },0);
            return {
                ...state,
                draft: {
                    ...state.draft,
                    products: [...line_items],
                    total: order_total
                }
            };
        case LOAD_ORDER_TO_DRAFT:
            const current_order_details = state.items[action.payload.order_id];
            let current_line_items = null;            
            if(current_order_details){
                current_line_items = current_order_details.line_items.map(line_item => {
                    
                    return { 
                        id: line_item.id,
                        product_id: line_item.product_id,
                        price: line_item.price,
                        name: line_item.name,
                        total: line_item.total,
                        sku: line_item.sku,
                        stock_quantity: line_item.stock_quantity,
                        featured_image: line_item.featured_image,
                        weight: line_item.weight,
                        quantity: line_item.quantity
                    }
                });
            }
            return {
                ...state,
                draft: {
                    products: [...current_line_items],
                    cancelled_note: current_order_details.cancelled_note ? current_order_details.cancelled_note: '',
                    purchase_order_number: current_order_details.purchase_order_number ? current_order_details.purchase_order_number: '',
                    wbs_code: current_order_details.wbs_code ? current_order_details.wbs_code : '',
                    cost_centre: current_order_details.cost_centre ? current_order_details.cost_centre : '',
                    date_created: current_order_details.date_created ? current_order_details.date_created: '',
                    date_required_by: current_order_details.date_required_by ? current_order_details.date_required_by : '',
                    contact_details: current_order_details.contact_details ? current_order_details.contact_details : '',
                    bp_code: current_order_details.bp_code ? current_order_details.bp_code : '',
                    dealer_code: current_order_details.dealer_code ? current_order_details.dealer_code : '',
                    reason: current_order_details.reason ? current_order_details.reason : '',
                    notes: current_order_details.notes ? current_order_details.notes : '',
                    order_approver: current_order_details.order_approver ? current_order_details.order_approver : '',
                    shipping: current_order_details.shipping ? current_order_details.shipping : {},
                    billing: current_order_details.billing ? current_order_details.billing : {},
                    status: current_order_details.status ? current_order_details.status : '',
                    shipping_lines: current_order_details.shipping_lines ? current_order_details.shipping_lines : '',
                    total: current_order_details.subtotal ? current_order_details.subtotal : 0                    
                }
            };
            case LOAD_ORDER_TO_EDIT:
                const order_to_edit = state.items[action.payload.order_id];
                current_line_items = null;            
                if(order_to_edit){
                    current_line_items = order_to_edit.line_items.map(line_item => {
                        
                        return { 
                            id: line_item.id,
                            product_id: line_item.product_id,
                            price: line_item.price,
                            name: line_item.name,
                            total: line_item.total,
                            sku: line_item.sku,
                            stock_quantity: line_item.stock_quantity,
                            featured_image: line_item.featured_image,
                            weight: line_item.weight,
                            quantity: line_item.quantity
                        }
                    });
                }

                let total_with_shipping = order_to_edit.subtotal ? order_to_edit.subtotal : 0;
                if (order_to_edit.shipping_lines && order_to_edit.shipping_lines.length > 0) {
                    total_with_shipping = (total_with_shipping * 1) + parseFloat(order_to_edit.shipping_lines[0].total);                    
                }
                return {
                    ...state,
                    edit: {
                        products: [...current_line_items],
                        cancelled_note: order_to_edit.cancelled_note ? order_to_edit.cancelled_note: '',
                        purchase_order_number: order_to_edit.purchase_order_number ? order_to_edit.purchase_order_number: '',
                        wbs_code: order_to_edit.wbs_code ? order_to_edit.wbs_code : '',
                        cost_centre: order_to_edit.cost_centre ? order_to_edit.cost_centre : '',
                        date_created: order_to_edit.date_created ? order_to_edit.date_created: '',
                        date_required_by: order_to_edit.date_required_by ? order_to_edit.date_required_by : '',
                        contact_details: order_to_edit.contact_details ? order_to_edit.contact_details : '',
                        bp_code: order_to_edit.bp_code ? order_to_edit.bp_code : '',
                        dealer_code: order_to_edit.dealer_code ? order_to_edit.dealer_code : '',
                        reason: order_to_edit.reason ? order_to_edit.reason : '',
                        notes: order_to_edit.notes ? order_to_edit.notes : '',
                        order_approver: order_to_edit.order_approver ? order_to_edit.order_approver : '',
                        shipping: order_to_edit.shipping ? order_to_edit.shipping : {},
                        billing: order_to_edit.billing ? order_to_edit.billing : {},
                        shipping_lines: order_to_edit.shipping_lines ? order_to_edit.shipping_lines : {},
                        status: order_to_edit.status ? order_to_edit.status : '',
                        total: total_with_shipping 
                    }
                };
        case LOGOUT:
            return initialState            
        default: return state;
    }
}