import React, { PureComponent } from 'react';
import styled from 'styled-components';

const StyledBreadcrumbs = styled.div`
    background: #f3f2ec;
    padding-top: 30px;
    .breadcrumbs__inner {
        max-width: 1000px;
        margin: 0 auto;
    }
`;

export class Breadcrumbs extends PureComponent {
    render() {
        return (
            <StyledBreadcrumbs>
                <div className="breadcrumbs__inner">                    
                {this.props.children}
                </div>
            </StyledBreadcrumbs>
        );
    }
}
export default Breadcrumbs;