export const SET_TOKEN = 'SET_TOKEN';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_SEARCH = 'UPDATE_SEARCH';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_STATUSES = 'FETCH_STATUSES';
export const REQUEST_ORDERS_PAGE = 'REQUEST_ORDERS_PAGE';
export const RECEIVE_ORDERS_PAGE = 'RECEIVE_ORDERS_PAGE';
export const UPDATE_LOADING = 'UPDATE_LOADING';
//ORDERS
export const LOAD_ORDER = 'LOAD_ORDER';

export const LOAD_ORDER_REQUEST = 'LOAD_ORDER_REQUEST';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_FAILURE = 'LOAD_ORDER_FAILURE';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const NEW_ORDER = 'NEW_ORDER';
export const SET_EDIT_ORDER = 'SET_EDIT_ORDER';
export const CLEAR_DRAFT_ORDER = 'CLEAR_DRAFT_ORDER';
export const UPDATE_DRAFT_ORDER = 'UPDATE_DRAFT_ORDER';
export const UPDATE_DRAFT_ORDER_STATUS = 'UPDATE_DRAFT_ORDER_STATUS';
export const LOAD_ORDER_TO_DRAFT = 'LOAD_ORDER_TO_DRAFT';
export const LOAD_ORDER_TO_EDIT = 'LOAD_ORDER_TO_EDIT';

export const UPDATE_DRAFT_FROM_ORDER = 'UPDATE_DRAFT_FROM_ORDER';
export const UPDATE_DRAFT_ORDER_PRODUCTS = 'UPDATE_DRAFT_ORDER_PRODUCTS';
export const UPDATE_DRAFT_ORDER_SHIPPING = 'UPDATE_DRAFT_ORDER_SHIPPING';

//PRODUCTS
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const NEW_PRODUCTS = 'NEW_PRODUCTS';
export const DRAFT_PRODUCT = 'DRAFT_PRODUCT';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const LOGOUT = 'LOGOUT';
export const FETCH_PRODUCTS_FROM_ORDER = 'FETCH_PRODUCTS_FROM_ORDER';