import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    color: black;
    border: none;
    font-weight: 500;
    font-size: 0.875rem;
    padding-top: 8px;
    padding-bottom: 5px;
    border-radius: 20px;
    padding: 8px 25px 5px;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    &.btn-primary {
        background-color: ${props=> props.theme.yellow};
        border-bottom: 3px solid ${props=> props.theme.darkYellow};
        &:hover {
            color: black;
            background-color: ${props=> props.theme.darkYellow};
        }
    }
    &.btn-link {
        text-transform: none;
        color: ${props=> props.theme.blue};
        padding: 0;
        appearance: none;
        -webkit-appearance: none;
        background: none;
        border: none;
    }
    &.btn-secondary {
        color: white;
        background-color: ${props=> props.theme.blue};
        border-bottom: 3px solid ${props=> props.theme.darkBlue};
        &:hover {
            color: black;
            background-color: ${props=> props.theme.darkBlue};
        }
    }
    &.btn-xxs {
        font-size: 0.75rem;
    }

`;

export default ({ 
    children, 
    onClick,
    className, 
    type, 
    disabled     
  }) => <Button 
    type={type}
    disabled={disabled}
    onClick={onClick}
className={className}>{children}</Button>;
