import React, { Component } from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  padding: 15px 0;
  font-size: 12px;
`;

export class Footer extends Component {
  render() {
    return (
      <StyledFooter>
        © { new Date().getFullYear() } | Sabel | <a href="/documentation">Documentation</a>
      </StyledFooter>
    )
  }
}

export default Footer;