import React, { Component } from "react";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { fetchProducts, updateSearch } from '../../actions/products';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProductRow from './ProductRow';
import { decodeHTML } from '../../utils/helpers';
import ProductImageModal from './ProductImageModal';
import axios from 'axios';

const StyledList = styled.div`
    width: 100%;
    height: calc(100% - 60px);
`;
const StyledFilters = styled.div`
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border-bottom: 1px solid #efefef;
    @media (min-width: 650px) {
        height: 60px;
    }

    button{
        background-color: #FDCC08;
        box-shadow: none;
        border: none;
        border-bottom: 3px solid #f6bd00;
        svg {
            width: 20px;
            fill: black;
            display: block;
        }
    }
    @media (min-width: 650px) {
        .form-inline {
            flex-grow: 1;
            .form-group {
                align-items: stretch;
                flex-grow: 1;
                input[type=text] {
                    border-radius: 4px 0 0 4px;
                    flex-grow: 1;
                }
                select {
                    border-radius: 0;
                    appearance: none;
                    border-left: none;
                    border-right: none;
                }
                button {
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }
    @media (max-width: 649px) {
        .form-inline {
            flex-grow: 1;
        }
        .form-group {
            flex-grow: 1;
            display: grid;
            grid-template-columns: 1fr 45px;
            margin: 0;
            input[type=text] {
                grid-column: 1/span2;
                border-radius: 4px 4px 0 0;
                border-bottom: none;
            }
            select {
                grid-column: 1;
                appearance: none;
                border-radius: 0 0 0 4px;
            }
            button {
                border-radius: 0 0 4px 0;    
            }
        }
    }
`;
class ProductsSearch extends Component {
    cancelTokenSignal = axios.CancelToken.source();

    constructor(){
        super();
        this.state = {
            search: [],
            color: '',
            showProductImage: false,
            image: null,
            title: null
        };
        this.itemSizes = [];
        this.onItemsRendered = this.onItemsRendered.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.getChildItemSize = this.getChildItemSize.bind(this);
        this.filterProducts = this.filterProducts.bind(this);
        this.list = null;
        this.active = false;
        this.SearchRef = React.createRef();
        this.CategoryRef = React.createRef();    
    }
    handleCloseProductImage = () => {
    this.setState({ showProductImage: false});
    }
    handleShowProductImage = (e, image, title) => {
    this.setState({ image: image, title: title, showProductImage: true});
    }
    isItemLoaded = index => !!this.props.items[index];
    loadMoreItems = (startIndex, stopIndex) => {
        return this.props.fetchProducts(startIndex, 20);
    };
    getChildItemSize = (index, size) => {
        this.itemSizes[index] = size;
        if (this.list) {
            this.list.resetAfterIndex(index);
        }
    }
    itemSize = index => {
        return this.itemSizes[index] ? this.itemSizes[index] : 61;
    }
    setupRef = element => {
        if (element) {
            this.list = element;
            this.itemSizes.forEach( (size, index) => {
                this.list.resetAfterIndex(index);
            })
        }
    }
    componentDidMount = () => {
        this.active = true;
        this.cancelTokenSignal = axios.CancelToken.source();
        this.filterProducts();
        this.props.fetchProducts(1, this.props.perPage,this.cancelTokenSignal).then(() => {
            this.loadProducts(2, this.props.perPage);
        });
    }
    componentWillUnmount = () => {
        this.active = false;
        this.cancelTokenSignal.cancel('Api is being canceled');
    }
    loadProducts(page, per_page) {
        if (this.active){
            this.props.fetchProducts(page, per_page,this.cancelTokenSignal).then(() => {
                if (this.active){
                    this.filterProducts();
                    const { total_pages } = this.props.products;
                    if (page < total_pages) {
                        this.loadProducts(page + 1, per_page);
                    }
                }
            });
        }
    }
    handleSearchChange = (e) => {
        this.filterProducts();
        // const total_items = filtered.length;
        // this.props.updateSearch(filtered, total_items);
    }
    filterProducts = () => {
        const products = {...this.props.products.items};
        const productsInOrder = [...this.props.draft_order.products];
        const search = (this.SearchRef.current ? this.SearchRef.current.value : "");
        const category = (this.CategoryRef.current ? parseInt(this.CategoryRef.current.value): "");
        let foundCategory = false;
        let color = '#FFFFFF';
        const productsInOrderArray = Object.assign({},...productsInOrder.map( item => {
            const id = item.product_id ? item.product_id:item.id;
            return { [id]: item};
        }));
        let filtered = Object.keys(products).map( key => ({ ...products[key] }));
        if (search !== ""){
            filtered = filtered
                .filter( product => {
                        if (!product.isLoading){
                            return product.name.toLowerCase().includes(search.toLowerCase()) || product.sku.toLowerCase().includes(search.toLowerCase());
                        }
                        return false;
                    }
                );
        }
        if (category){
            const categories = this.props.products.categories;
            let categories_keys = [];
            categories_keys = Object.keys(categories).filter( item => {
                return categories[item].parent === category || categories[item].id === category;
            });
            filtered = filtered
                .filter( product => {
                        if (!product.isLoading){
                            if (product.categories.length > 0){
                                foundCategory = false;
                                product.categories.forEach(item => {
                                    if(categories_keys.includes(item.id.toString())){
                                        foundCategory = true;
                                        return true;
                                    }                                                                        
                                })
                            }
                        }
                        return foundCategory;
                    }
                )
                if (categories[`${category}`]){
                    color = categories[`${category}`].color;
                }
        }
        if (search === "" && !category){
            if (filtered.length < this.props.products.total_items){
                filtered = filtered.concat(new Array(this.props.products.total_items - filtered.length).fill({
                    isLoading: true
                }));
            }
        }
        filtered = filtered.map( item => {
            if (typeof productsInOrderArray[item.id] !== 'undefined'){
                return {
                    ...item,
                    quantity: productsInOrderArray[item.id].quantity
                }
            } else {
                return {
                    ...item,
                    quantity: ""
                }
            }
        })
        this.setState({
            search: filtered,
            color
        });
        return filtered;
    }
    onItemsRendered({start, end}){
        for(let i = start; i < end; i++){
            this.list.resetAfterIndex(i);
        }
    }
    componentWillUnmount() {
        this.active = false;
    }
    render() {
        const { updateProductInOrder, products } = this.props;
        const { categories } = products;
        const categories_partnered = [];
        Object.keys(categories).forEach( category => {
            if (categories[category].parent > 0) {
                if (!categories_partnered[categories[category].parent]){
                    categories_partnered[categories[category].parent] = [];
                }
                if (!categories_partnered[categories[category].parent]['children']){
                    categories_partnered[categories[category].parent]['children'] = [];
                }
                categories_partnered[categories[category].parent]['children'].push(categories[category]);
            } else {
                if (!categories_partnered[categories[category].id]){
                    categories_partnered[categories[category].id] = [];
                    categories_partnered[categories[category].id]['data'] = null;
                    categories_partnered[categories[category].id]['children'] = [];
                }
                categories_partnered[categories[category].id]['data'] = categories[category];
            }
        });
        const { search } = this.state;
        return (
            <>
            <StyledFilters>
                <form action="" className="form-inline">
                    <div className="form-group">
                        <input ref={this.SearchRef} type="text" className="form-control" placeholder="Start typing. e.g. Base for..." onChange={(e) => this.handleSearchChange(e)}/>
                        { categories_partnered.length > 0 && (
                            <select ref={this.CategoryRef} name="" id="" className="form-control"  onChange={(e) => this.handleSearchChange(e)}>
                                <option value="">Select a category</option>
                                { categories_partnered.map( category => {
                                    const { id, name } = category['data'];
                                    const children = category['children'];
                                    let option = '';
                                    if (children.length > 0) {
                                        option = () => {
                                            return (<optgroup key={id} label={name}>
                                                <option value={id}>All {name}</option>
                                                { Object.keys(children).map( child => {
                                                    const {id, name } = children[child];
                                                    return (<option key={child} value={id}>{decodeHTML(name)}</option>)
                                                })}
                                            </optgroup>);
                                        } 
                                    } else {
                                        option = () => {
                                        return (<option key={id} value={id}>{decodeHTML(name)}</option>)
                                        }
                                    }
                                    return option();                                    
                                })}
                            </select>
                        )}
                        <button type="button" className="btn btn-primary"><svg viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg"><title>Search</title><path d="M15.42859 9.57141c0-3.308032-2.691968-6-6.000014-6-3.308033 0-6 2.691968-6 6 0 3.308047 2.691967 6.000014 6 6.000014 3.308046 0 6.000014-2.691967 6.000014-6.000014zm6.857151 11.142878c0 .937501-.776787 1.714288-1.714288 1.714288-.455358 0-.897322-.187501-1.205358-.50893l-4.593756-4.580362C13.205373 18.424106 11.330371 19 9.428583 19 4.218755 19 0 14.781245 0 9.571417 0 4.36159 4.218755.142835 9.428583.142835c5.209827 0 9.428582 4.218755 9.428582 9.428582 0 1.901788-.575893 3.776791-1.660716 5.343757l4.593756 4.593755c.308036.308036.495536.750001.495536 1.205359z"></path></svg></button>
                    </div>
                </form>
            </StyledFilters>
            <StyledList>
                <AutoSizer>
                    {({ height, width }) => (
                    <List
                        className="List"
                        height={height}
                        itemCount={search.length}
                        itemSize={this.itemSize}
                        estimatedItemSize={61}
                        width={width}
                        ref={ this.setupRef }
                        itemData={{ products: search, color: this.state.color, showProductImage: this.handleShowProductImage, updateProductInOrder: updateProductInOrder, getChildItemSize: this.getChildItemSize }}
                        onItemsRendered={this.onItemsRendered}
                    >
                        {ProductRow}
                    </List>
                    )}
                </AutoSizer>
            </StyledList>
            <ProductImageModal show={this.state.showProductImage} title={this.state.title} image={this.state.image} handleCloseProductImage={this.handleCloseProductImage}/>
            </>
        );
    };
}
ProductsSearch.propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    updateSearch: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        products: state.products,
        draft_order: state.orders.draft
    }
}
export default connect(mapStateToProps, { fetchProducts, updateSearch })(ProductsSearch)