import { createBrowserHistory } from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

export function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch(e){
        console.log(e)
    }
}
function loadFromLocalStorage() {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch(e) {
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();


export const history = createBrowserHistory();
const middleware = [thunk, routerMiddleware(history)];
const env = 'dev';

if (persistedState) {
    if (typeof persistedState.router !== undefined) {
        persistedState.router.location.pathname = window.location.pathname;
    }
}
//history.push(window.location.pathname);

export default function configureStore(preloadedState) {
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    let addOn = applyMiddleware(
        ...middleware
    );
    if ( env === 'dev' ){
        addOn = composeEnhancer(
            applyMiddleware(
                ...middleware
            )
        );
    }
    const store = createStore(
        rootReducer(history),
        persistedState,
        addOn
    );
    return store;
};