import React, { Component } from 'react';
import ProductItem from './ProductItem';
import styled from 'styled-components';
import Button from '../../components/forms/Button';
import ProductImageModal from './ProductImageModal';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 10px 15px;
  border-bottom: 1px solid #efefef;
  font-size: 0.875rem;
  .product__image {
    align-self: flex-start;
    cursor: pointer;
  }
  .product__description {
    display: flex;
    align-items: center;
    width: 70%;
  }
  .product__price-details {
      display: none;
      width: 100%;
      justify-content: space-between;
      @media (min-width: 600px) {
        display: flex;
        align-items: center;
        width: 328px;
        justify-content: stretch;
      }
  }
  .product__price{
      text-align: right;
      padding-right: 15px;
      width: 120px;
      vertical-align: middle;
  }
  .product__quantity {
    width: 88px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-right: 15px;
    text-align: right;
  }
  .product__total {
    width: 120px;
    padding-right: 15px;
    text-align: right;
  }
`;
const StyledEmpty = styled.div`
  padding: 20px;
  text-align: center;
  background-color: #efefef;
  font-style: italic;
`;
const StyledActions = styled.div`
  padding: 20px;
  background-color: #efefef;
  text-align: center;
  margin: 5px 0 0;
  border-radius: 0 0 4px 4px;
`;
export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showProductImage: false,
        image: null,
        title: null
    };        
  }
  handleCloseProductImage = () => {
    this.setState({ showProductImage: false});
  }
  handleShowProductImage = (e, image, title) => {
    this.setState({ image: image, title: title, showProductImage: true});
  }
  renderItem = product => {
    const the_id = product.product_id ? product.product_id: product.id;
    return <ProductItem key={the_id} index={the_id} product={ product } value={product.quantity} showTotal showProductImage={this.handleShowProductImage} updateProduct={this.props.updateProductInOrder} deleteProduct={this.props.deleteProductInOrder} showDeleteButton={this.props.showDeleteButton} showQuantityInput={this.props.showQuantityInput} showQuantityInputOutOfStock={this.props.showQuantityInputOutOfStock} isReadOnly={this.props.isReadOnly}/>
  }
  render() {
    const { products, showTotal, isLoading } = this.props;
    let productsList = [];
    if (typeof products === 'object'){
      productsList = Object.values(products)
    } else {
      productsList = products;
    }
    return (
      <div>
        { productsList.length === 0 && (
          <StyledEmpty>
            <p>You haven't added products to the order.</p>
            <Button type="button" className="btn btn-primary" onClick={this.props.handleShowProducts}>Start adding products</Button>
          </StyledEmpty>
        )}
        { productsList.length > 0 &&
        <StyledDiv>
          <div className="product__description">Description</div>
          <div className="product__price-details">
            <div className="product__price">Price</div>
            <div className="product__quantity">Quantity</div>                
            { showTotal && 
              <div className="product__total">Total</div>
            }
          </div>
        </StyledDiv>
        }
        {!isLoading && 
            productsList.map(this.renderItem)
        }            
        {
          isLoading && 
          <div>Loading</div>
        }
        { (productsList.length > 0 && !this.props.isReadOnly) && (
          <StyledActions>
            <Button type="button" className="btn btn-primary" onClick={this.props.handleShowProducts}>{ products.length > 0 ? 'Add more products' : 'Start adding products'}</Button>
          </StyledActions>
        )}
        <ProductImageModal show={this.state.showProductImage} title={this.state.title} image={this.state.image} handleCloseProductImage={this.handleCloseProductImage}/>
      </div>
    )
  }
}

export default ProductList;