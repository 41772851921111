import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginPage from '../pages/LoginPage';

export default function(ComposedComponent){
    class Authenticate extends Component {
        render() {
            if(!this.props.isAuthenticated){
                return (<LoginPage/>);
            } else {
                return (
                    <ComposedComponent {...this.props}/>
                )
            }
        }
    }

    Authenticate.propTypes = {
        isAuthenticated: PropTypes.bool.isRequired
    }

    function mapStateToProps(state) {
        return {
            isAuthenticated: state.auth.isAuthenticated
        };
    }

    return connect(mapStateToProps)(Authenticate);
}