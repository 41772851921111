import React from 'react';
import styled from 'styled-components';

const Label = styled.div`
    background-color: black;
    color: white;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 20px;
    line-height: 1;
    white-space: nowrap;
    font-size: 12px;
    &.awaiting-approval {
        background-color: ${props => props.theme.status.awaiting_approval.bg };
        color: ${props => props.theme.status.awaiting_approval.color };
    }
    &.pending {
        background-color: ${props => props.theme.status.pending.bg };
        color: ${props => props.theme.status.pending.color };
    }
    &.on-hold {
        background-color: ${props => props.theme.status.on_hold.bg };
        color: ${props => props.theme.status.on_hold.color };
    }
    &.processing {
        background-color: ${props => props.theme.status.processing.bg };
        color: ${props => props.theme.status.processing.color };
    }
    &.waiting-stock-status {
        background-color: ${props => props.theme.status.waiting_stock_status.bg };
        color: ${props => props.theme.status.waiting_stock_status.color };
    }
    &.cancelled {
        background-color: ${props => props.theme.status.cancelled.bg };
        color: ${props => props.theme.status.cancelled.color };
    }
    &.approved {
        background-color: ${props => props.theme.status.approved.bg };
        color: ${props => props.theme.status.approved.color };
    }
    &.shipped, &.completed {
        background-color:${props => props.theme.status.shipped.bg };
        color: ${props => props.theme.status.shipped.color };
    }
`;

export default ({ 
    children,
    type    
  }) => <Label className={ type }>{children}</Label>;
