import React, { Component } from 'react'
import { connect } from 'react-redux';
import Button from '../forms/Button';
import styled from 'styled-components';
import Footer from './Footer';
import { setCurrentStore } from '../../actions/auth';
import { Container } from './Container';
import Select from 'react-select';

const StyledPage = styled.div`
    background: ${props => props.theme.bodyBackground };   
    padding-top: 30px;
    opacity: 1;
    transition: opacity 0.3s ease-out;
    min-height: calc(100vh - 65px);
    position: relative;
    &.loading {
        opacity: 0;
    }
    .page-header {
      margin-bottom: 20px;
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: space-between;        
      }
    }
    .page-header__left {
      display: flex;
      align-items: center;
    }
    .page-header__title-top {
      display: flex;
      align-items: center;
    }
    .page-header__right {
      display: flex;
      align-items: center;
      padding-top: 15px;
      @media (min-width: 768px) {
        padding-top: 0;
      }
    }
    .page-header__date {
      font-size: 15px;
    }
    h1 {
      color: ${props => props.theme.bodyColor };   
      margin-right: 20px;
    }
`;
export class Page extends Component {
  constructor() {
    super();
    this.handleDealerCodeChange = this.handleDealerCodeChange.bind(this);
  }
  handleDealerCodeChange(object, action) {
    if (action.action === 'select-option'){
        this.props.setCurrentStore(object.value).then( res => {
          if (this.props.handleChange) {
            this.props.handleChange();
          }
        });
    } else {
      this.props.setCurrentStore(null).then( res => {
        if (this.props.handleChange) {
          this.props.handleChange();
        }
      });
    }
  }
  goToDocumentation() {
    window.location.href = '/documentation';
  }
  render() {
    const { user, auth } = this.props;
    let options = null;
    let option_selected = null;
    
    if (user.stores) {
      options = user.stores.map( store => {
        return { value: store.dealer_code, label: `${store.dealer_code}: ${store.billing.company ? store.billing.company: store.billing.first_name}`};
      });
      if (auth.current_store) {
        const selected_store = user.stores.find( store => {
          return store.dealer_code === auth.current_store;
        });
        if (selected_store) {
          const label = `${selected_store.dealer_code}: ${selected_store.billing.company ? selected_store.billing.company: selected_store.billing.first_name}`;
          option_selected = { label: label, value: selected_store.dealer_code};
        }
      }
    }
    return (
      <StyledPage className={user ? '':'loading'}>
        <Container>
        <Button type="button" className="btn-secondary btn-xxs" onClick={this.goToDocumentation}>Documentation</Button>
        </Container>
        <Container>
          { user.stores && user.stores.length > 1  &&
            <><br/><Select options={options} onChange={this.handleDealerCodeChange } value={option_selected} isClearable placeholder="Select store"/></>
          }
          <br/>
          {this.props.title && 
            <div className="page-header">
              <div className="page-header__left">
                <div className="page-header__title">
                  <div className="page-header__title-top">
                    <h1>{this.props.title}</h1>
                    {this.props.titleExtras && 
                      this.props.titleExtras
                    }
                  </div>
                  {this.props.date && <div className="page-header__date">{this.props.date }</div>}
                </div>                
              </div>
              <div className="page-header__right">
                {this.props.actions !== 'undefined' && this.props.actions}
              </div>
            </div>
          }
          {this.props.children}
          <Footer></Footer>
        </Container>
      </StyledPage>
    )
  }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        user: state.auth.user
    }
}

export default connect(mapStateToProps, { setCurrentStore })(Page)
