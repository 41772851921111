export function formatPrice(cents){
    const value = isNaN(parseFloat(cents))?0:  parseFloat(cents);
    return (value).toLocaleString("en-AU", {
        style: "currency",
        currency: "AUD"
    });
}
export const decodeHTML = function (html) {
	var txt = document.createElement('textarea');
	txt.innerHTML = html;
	return txt.value;
};
export function sortObj(list, key) {
    function compare(a, b) {
        a = a[key];
        b = b[key];
        var type = (typeof(a) === 'string' ||
                    typeof(b) === 'string') ? 'string' : 'number';
        var result;
        if (type === 'string') result = a.localeCompare(b);
        else result = a - b;
        return result;
    }
    return list.sort(compare);
}