import React, { Component } from 'react';
import HeaderUser from './HeaderUser';
import styled from 'styled-components';
import { Container } from '../page/Container';
import Logo from '../../logo-vitag-optus-platform.svg';
import { Link } from 'react-router-dom';

const StyledHeader = styled.div`
    background: ${props => props.theme.headerBackground };   
    padding: 10px 0;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    position: relative;
    z-index: 2;
    color: ${props => props.theme.headerColor };   
    a {
      color: ${props => props.theme.headerLinkColor };   
      text-decoration: none;
    }
    > div {
        display: flex;
        align-items: center;
        justify-content: space-between;        
    }

    .logo-optus-platform {
      display: block;
      max-width: 250px;
      width: 100%;
      height: 18px;
    }
    .logo-vitag__wrap {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #37b1bf;
        display: inline-block;
        img {
            max-width: 80px;
        }
    }
    .logo-optus-platform__optus {
      fill: ${props => props.theme.logoOptusColor };   
    }
    .logo-optus-platform__platform {
      fill: ${props => props.theme.logoOptusPlatformColor };
    }
    svg {
        width: 140px;
    }
`;

export class Header extends Component {
  render() {
    return (
      <StyledHeader>
          <Container>
              <div className="logos">
              <Link to="/">
                <img src={Logo} className="logo-optus-platform" alt="Vitag Optus platform"/>
                </Link>
            </div>
            <HeaderUser/>
          </Container>          
      </StyledHeader>
    )
  }
}

export default Header
