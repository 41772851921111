import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const StyledNavLink = styled(NavLink)`
    color: black;
    border: none;
    font-weight: 500;
    font-size: 0.875rem;
    padding-top: 8px;
    padding-bottom: 5px;
    transition: background-color 0.3s ease-out;
    padding: 8px 25px 5px;
    border-radius: 20px;
    &.btn-primary {
        background-color: ${props=> props.theme.yellow};
        border-bottom: 3px solid ${props=> props.theme.darkYellow};
        &:hover {
            color: black;
            background-color: ${props=> props.theme.darkYellow};
        }
    }
`;

export default (props) => <StyledNavLink 
    { ...props }>{props.children}</StyledNavLink>;
