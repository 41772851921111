import React, { Component } from 'react';
import OrderListItem from './OrderListItem';
import styled from 'styled-components';

const StyledTh = styled.th`
  white-space: nowrap;
  &.column-number {
    text-align: right;
  }
`;

export class OrdersList extends Component {
  render() {
    const { orders, isLoading, showPurchaseOrderNumber, showCustomerDetails, isSearchResult } = this.props;
    let ordersList = [];
    if (typeof orders === 'object'){
      ordersList = Object.values(orders);
    } else {
      ordersList = orders;
    }
    return (
      <div>
        <div className="table-responsive table-hover">
        <table className="table">
        <thead>
          <tr>
            <StyledTh>Order #</StyledTh>
            <StyledTh>Status</StyledTh>
            <StyledTh>Invoice #</StyledTh>
            { showCustomerDetails && 
            <>
            <StyledTh>Customer</StyledTh>
            <StyledTh>Dealer Code</StyledTh>
            </>
            }
            { showPurchaseOrderNumber && 
            <StyledTh>Purchase Order #</StyledTh>
            }
            <StyledTh>Date</StyledTh>
            <StyledTh className="column-number">Items</StyledTh>
            { this.props.showReorder && <StyledTh></StyledTh>}
            <StyledTh className="column-number">Total</StyledTh>
          </tr>
        </thead>
        <tbody>
        { isLoading && 
          <tr><td colSpan={ this.props.showReorder  ? 7 : 6}>Loading...</td></tr>
        }
        { (ordersList.length === 0 && !isLoading && !isSearchResult ) &&
          <tr><td colSpan={ this.props.showReorder  ? 7 : 6}>You have no orders yet.</td></tr>
        }
        { (ordersList.length === 0 && !isLoading && isSearchResult) && 
          <tr><td colSpan={ this.props.showReorder  ? 7 : 6}>No orders found</td></tr>
        }
        { ordersList.map( order => 
            <OrderListItem key={order.id} showCustomerDetails={showCustomerDetails} showPurchaseOrderNumber={showPurchaseOrderNumber} order={ order}  handleClick={this.props.handleClick} showReorder={this.props.showReorder} buttonAction={this.props.buttonAction} buttonName={this.props.buttonName}></OrderListItem>
        )}
        </tbody>
        </table>
        </div>
      </div>
    )
  }
}

export default OrdersList
