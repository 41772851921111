import React, { PureComponent } from 'react'
import styled from 'styled-components';
import { formatPrice } from '../../utils/helpers';
import renderHTML from 'react-render-html';

// import shallowCompare from 'react-addons-shallow-compare'

const StyledProduct = styled.div`
    background: white;
    border-bottom: 1px solid #efefef;
    padding: 10px 15px;
    font-size: 1rem;
    &:last-child {
      border:none;
    }
    @media (min-width: 600px) {
      font-size: 0.875rem;
    }
    .product {
      display: flex;
      align-items: center;
      justify-content: space-between;    
      flex-wrap: wrap;
      @media (min-width: 600px) {
        flex-wrap: nowrap;
      }
    }
    &.product--outofstock {
      background-color: #f9f9f9;
      color: #ababab;
      .product__sku {
        color: #ccc;
      }
    }
    .image {
        padding: 10px 15px 10px 10px;
    }
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    .product__image {
      align-self: flex-start;
      cursor: pointer;
    }
    .product__image-wrap {
      margin-right: 15px;
      width: 40px;
      height: 40px;
      background-color: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px;
    }
    .product__description {
      display: flex;
      align-items: center;
      width: 100%;
      @media (min-width: 600px) {
        width: 70%;
      }
    }
    .product__price-details {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (min-width: 600px) {
        width: 328px;
        justify-content: stretch;
      }
    }
    .product__price-quantity {
      width: 50%;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: space-between;
      padding: 8px 0 10px;
      @media (min-width: 600px) {
        padding: 0;
        width: 208px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
      }
      .times {
        font-size: 0.92em;
        margin-right: .25em;
      }
    } 
    .product__price{
        text-align: right;
        padding-right: 15px;
        vertical-align: middle;
        @media (min-width: 600px) {
          width: 120px;
        }
    }
    .product__quantity {
      flex-grow: 0;
      flex-shrink: 0;
      text-align: right;
      padding-right: 15px;
      @media (min-width: 600px) {
        width: 88px;
      }
    }
    .product__quantity-input {
      @media (min-width: 600px) {
        width: 88px;
      }
    }
    .product__total{
      text-align: right;
      vertical-align: middle;
      width: 100%;
      @media (min-width: 600px) {
        width: 120px;
        padding-right: 15px;
      }
    }
    .product__out-of-stock {
      font-size: 12px;
      color: #dc9d00;
      background: #fdfaf0;   
      padding: 6px;
      text-align: center;
      margin: 5px -15px -9px;
    }
    .product__sku {
      font-size: 12px;
      color: #6c757d;
      font-weight: bold;
    }
`;
const StyledLoadingProduct = styled.div`
  color: #ccc;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #efefef;
  font-size: 0.875rem;
  .product__image {
    align-self: flex-start;
  }
  .product__image-wrap {
    margin-right: 15px;
    width: 40px;
    height: 40px;
    background-color: #efefef;
  }
  .product__description {
    display: flex;
    align-items: center;
    width: 70%;
  }
  .product__description-text {
    border-radius: 5px;
    background-color: #efefef;
    height: 16px;
    width: 60%;
  }
  .product__price{
      font-weight: bold;
      text-align: right;
      padding-right: 15px;
      width: 120px;
      vertical-align: middle;
  }
  .product__price-text {
    border-radius: 5px;
    background-color: #efefef;
    height: 16px;
    width: 80px;
    padding-right: 15px;
  }
  .product__quantity {
    width: 88px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
  }
  .product__quantity-input {
    width: 88px;
    height: 38px;
    border-radius: 2px;
    border: 1px solid #efefef;
  }
  .product__total{
    text-align: right;
    padding-right: 15px;
    width: 120px;
    vertical-align: middle;
  }
`;
const StyledButton = styled.button`
  border: none;
  background: none;
  color: ${props => props.theme.red };   
`;
class ProductItem extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      quantity: ""
    };
  }
  componentDidMount() {
    this.setState({
      quantity:this.props.product.quantity
    });
    
  }
  handleChange = (quantity) => {
    const updateProduct = {
      ...this.props.product,
      quantity: (quantity === "") ? "" : parseInt(quantity)
    }
    this.setState({
      quantity: (quantity === "") ? "" : parseInt(quantity)
    });
    this.props.updateProduct(updateProduct);
  };
  render() {
    const { isLoading } = this.props.product;
    const { innerRef } = this.props;
    if (isLoading) {
      return (
        <StyledLoadingProduct ref={innerRef}>
          <div className="product__image">
            <div className="product__image-wrap"></div>
          </div>
          <div className="product__description">
            <div className="product__description-text"></div>
          </div>
          <div className="product__price">
            <div className="product__price-text"></div>
          </div>
          <div className="product__quanity">
            <div className="product__quantity-input"></div>
          </div>
        </StyledLoadingProduct>  
      );
    }
    
    const { featured_image, id, product_id, name, price, price_html, images, quantity, description, sku, stock_quantity, stock_status, meta_data } = this.props.product;
    const the_id = product_id ? product_id: id;
    const { value, defaultValue, color } = this.props;
    const total = (quantity ? formatPrice(parseFloat(price) * parseInt(quantity)) : formatPrice(0) );
    let image = null;
    let extraProps = {}
    if (defaultValue) {
      extraProps = {
        defaultValue
      };
    } else {
      extraProps = {
        value
      };
    }
    if (images && images.length > 0) {
      image = images[0].src;
    } else {
      if (featured_image ){
        image = featured_image;
      }
    }
    const returnQuantityTotals = () => {
      return (<>
      <div className="product__price-details">
        <div className="product__price-quantity">
          <div className="product__price">
              {/* { formatPrice(price * 1) } */}
              { price_html ? renderHTML(price_html) : (<>{formatPrice(price)}<br/><small>exc. GST</small></>) }              
         </div>
         {
          this.props.showQuantityInput ? (
            <>
            <div className="product__quantity">
              <input type="number" min="0" name={`quantity-${the_id}`} onChange={e => this.handleChange(e.target.value)} data-id={the_id} className="form-control product__quantity-input" {...extraProps}/>
            </div>
            </>
        ): (
          <div className="product__quantity">
              <small className="times">×</small> {quantity}
            </div>
        )}
        </div>
        {
          this.props.showTotal && (
            <>
        <div className="product__total">
              { total } <br/><small>exc. GST</small>
            </div>
            </>
        )}
        {
          this.props.showDeleteButton && (
            <div>
              <StyledButton type="button" onClick={(e) => this.props.deleteProduct(the_id)}><i className="far fa-trash-alt"></i></StyledButton>
            </div>
        )}</div></>
      );
    }
    const showMetaOutOfStock = () => {
      if (meta_data.length > 0) {
        const message = meta_data.filter( item => {
          return item.key === "out_of_stock_message";
        });
        if (message.length > 0) {
          return ( 
            <div className="product__out-of-stock">
              {message[0].value}
            </div>
          )
        }
      }
      return (
        <div className="product__out-of-stock">Out of stock</div>
      );
    }
    return (
      <StyledProduct className={ stock_status && `product--${stock_status}`} ref={innerRef} style={ color && ({borderLeftColor: `${color}`, borderLeftWidth:'6px', borderLeftStyle: 'solid'})}>
        <div className="product">
          <div className="product__description">
            <div className="product__image" onClick={(e) => this.props.showProductImage(e, image, name)}>
              <div className="product__image-wrap">
              { image && 
                  <img className="product__img" src={ image } alt={name}/>
              }
              </div>
            </div>
            <div className="product__name">
                { name } { description && (<span>{renderHTML(description)}</span>)} { sku && (<><div className="product__sku">SKU: {sku}</div></>)}
                <div>{ stock_quantity } in stock</div>
            </div>
           
          </div>
          {stock_status === 'outofstock' ? 
            <div>
              {
          this.props.showQuantityInputOutOfStock ? (
            <>
            <div className="product__quantity">
              <input type="number" min="0" name={`quantity-${the_id}`} onChange={e => this.handleChange(e.target.value)} data-id={the_id} className="form-control product__quantity-input" {...extraProps}/>
            </div>
              </>
          ): (
            <div className="product__quantity">
                <small className="times">×</small> {quantity}
              </div>
          )}{
            this.props.showDeleteButton && (
              <div>
                <StyledButton type="button" onClick={(e) => this.props.deleteProduct(the_id)}><i className="far fa-trash-alt"></i></StyledButton>
              </div>
          )}
            </div>
          : <>{returnQuantityTotals()}</>}                   
         </div>
         {stock_status === 'outofstock' ? <>{showMetaOutOfStock()}</> : null}
      </StyledProduct>
    )
  }
}

export default React.forwardRef((props, ref) => <ProductItem innerRef={ref} {...props}/>)
