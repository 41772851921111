import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const StyledModal = styled(Modal)`
    .modal-dialog {
        max-width: 600px;
        margin: 0 auto;
        padding: 40px 0;
    }
    .modal-title {
        font-size: 16px;
        font-weight: 300;
    }
    img {
        width: 100%;
        display: block;
    }
    .modal-body {
        padding: 0;
    }
`;
export class ProductImageModal extends Component {
  render() {
      const { title, image } = this.props;
    return (
        <StyledModal backdropClassName="product-image-overlay" centered dialogClassName="modal-90w modal-product-image" show={this.props.show} onHide={this.props.handleCloseProductImage}>
          <Modal.Header closeButton>
              <Modal.Title>{title}</Modal.Title>                            
          </Modal.Header>
          <Modal.Body>
              {image && <img src={image} alt="Large"/>}
          </Modal.Body>          
        </StyledModal>
    )
  }
}

export default ProductImageModal;
