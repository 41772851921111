import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../components/page/Page';
import { Container } from '../components/page/Container';
import Header from '../components/header/Header';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { newOrder } from '../actions/orders';
import Footer from '../components/page/Footer';
import { formatPrice } from '../utils/helpers';
import ProductList from '../components/products/ProductList';
import Status from '../components/components/Status';

const StylePage = styled.div`
    background: ${props => props.theme.blue };   
    min-height: 100vh;    
`;
const StyledOrderDetails = styled.div`
    background:white;
    padding: 30px;      
`;
const StyledOrderProducts = styled.div`
    background:white;
    padding: 30px;      
`;
const StyledTotals = styled.div`
    margin-top: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: flex-end;
    .box {
        background: white;
        padding: 30px;
        min-width: 300px;
    }
    table {
        width: 100%;
    }
    .order__total {
        text-align: right;
        font-size: 24px;
    }
`;
const StyledActions = styled.div`
    text-align: right;
    padding: 0;
    padding-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #14a7b3;
    margin-bottom: 10px;
`;
export class OrdersPage extends Component {
    componentDidMount() {

    }
    render() {
      const { id } =this.props.match.params;
        const order = this.props.orders.find( order => {
          return order.id.toString() === id;
        });
        const { status, date_required_by, contact_details, order_approver, reason } = order;
        const products_list = order.line_items;
        const products = products_list.map( item => {
          return {
            ...this.props.products.items[item.id],
            ...item
          };
        })
        const shipping = order.shipping_address;
        const total = products_list.reduce( (acumulator, item) => {
          return acumulator + (parseInt(item.quantity) * parseFloat(item.price));
        },0);
        return (
          <StylePage>
                <Page>
                <Header/>
                <Container>
                    <div className="title">
                        <h1>Order #{id}</h1>
                    </div>
                    <StyledActions>
                        <Status type={status}>{status}</Status>                        
                        <div><label htmlFor="">Change status: </label>
                        <select name="status" id="">
                        <option value="approved">Approved</option>
                        <option value="awaiting-approval">Awaiting approval</option>
                        <option value="processing">Processing</option>
                        </select>
                        </div>
                    </StyledActions>
                    <div className="order">
                        <div className="row align-items-start">
                            <div className="col-lg-4">
                                <StyledOrderDetails>
                                    <div className="form-group">
                                        <label htmlFor="date_required_by" className="form-label">Date of products required by</label>
                                        <p>{date_required_by}</p>
                                    </div>     
                                    <div className="form-group">
                                      <label htmlFor="">Contact details</label>
                                        <p>{contact_details}</p>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="">Order approver</label>
                                      <p>{order_approver}</p>
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="">Reason for request</label>
                                      <p>{reason}</p>
                                    </div>
                                    { shipping && (
                                        <div className="form-group"><label className="form-label">Store Address:</label><br/>{ shipping.address_1}{ shipping.address_2 && (<> {shipping.address_2}</>)} {shipping.city} {shipping.state} {shipping.postcode}</div>
                                    )}                                             
                                </StyledOrderDetails>
                            </div>
                            <div className="col-lg-8">
                                <StyledOrderProducts>
                                    <ProductList isLoading={false} products={products} showTotal isReadOnly={true} />
                                </StyledOrderProducts>
                            </div>
                        </div>
                        </div>
                </Container>
                <Container>
                    <StyledTotals>
                        <div className="box">
                            <table> 
                                <thead> 
                                    <tr>
                                        <th>Total: </th><td className="order__total">{formatPrice(total)}</td>
                                    </tr>
                                </thead>
                            </table>
                        </div>                            
                    </StyledTotals>
                </Container>
                    <Footer/>
                </Page>
            </StylePage>       
        )
    }
}

OrdersPage.propTypes = {
    auth: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    products: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
        auth: state.auth,
        products: state.products,
        orders: state.orders.items,
        order: state.orders.item
});

export default connect(mapStateToProps, { newOrder })(OrdersPage)
