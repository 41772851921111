import { FETCH_PRODUCTS, FETCH_PRODUCTS_FROM_ORDER, FETCH_CATEGORIES, UPDATE_SEARCH, LOGOUT } from '../actions/types';

const initialState = {
    items: {},
    search: [],
    per_page: 10,
    hasNextPage: true,
    total_items: 0,
    total_pages: 0,
    categories: {},
    total_categories: 0,
    total_categories_pages: 0,
    categories_page: 10
}
export default (state = initialState, action = {}) => {
    switch(action.type) {
        case FETCH_PRODUCTS:
            const items = { ...state.items};
            action.payload.items.forEach(product => {
                const { id, name, images, price, price_html, categories, description, sku, meta_data,stock_quantity, stock_status, weight } = product;
                const item = {
                    id,
                    name,
                    price,
                    price_html,
                    images,
                    categories,
                    description,
                    sku,
                    stock_quantity,
                    stock_status,
                    meta_data,
                    isLoading: false,
                    weight: parseFloat(weight)
                };
                items[`c${id}`] = item;
            });
            let hasNextPage = true;
            if (items.length === action.payload.total_items){
                hasNextPage = false;
            }
            return {
                ...state,
                page: action.payload.page,
                per_page: action.payload.per_page,
                items,
                hasNextPage,
                total_items: action.payload.total_items,
                total_pages: action.payload.total_pages
            };
        case UPDATE_SEARCH:
            let searchResults = Array(action.payload.total_items).fill({
                    isLoading: true    
            });
            for (var i = 0; i < action.payload.search.length; i++) {
                searchResults[i] = action.payload.search[i];                
            }
            return {
                ...state,
                search: searchResults
            };
        case FETCH_CATEGORIES:
            const categories = { ...state.categories };
            action.payload.categories.forEach( data => {
                const { cat_ID, name, slug, parent, category_count, color } = data;
                const category = {
                    id: cat_ID,
                    name,
                    slug,
                    parent,
                    count: category_count,
                    color,
                    isLoading: false
                };
                categories[`${cat_ID}`] = category;
            });
            return {
                ...state,
                categories,
                total_categories: action.payload.total_categories,
                total_categories_pages: action.payload.total_categories_pages,
                categories_page: action.payload.categories_page
            };      
        case FETCH_PRODUCTS_FROM_ORDER:
            const itemsFromOrder = { ...state.items};
            const orders = action.payload.items;

            orders.forEach(order => {
                const { line_items } = order;

                line_items.forEach( item => {
                    if (item.product){
                        const product = item.product;
                        itemsFromOrder[`c${product.id}`] = product;
                    }
                });
            })
            return {
                ...state,
                items: itemsFromOrder
            };    
        case LOGOUT:
            return initialState
        default: return state;
    }
}