import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

export class WordpressLogout extends Component {

  render() {
    return (
      <div>
        <Redirect to="/wp-login.php?action=logout"/>
      </div>
    )
  }
}

export default WordpressLogout

