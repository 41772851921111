import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth';
import orders from './orders';
import products from './products';

export default (history) => combineReducers({
    router: connectRouter(history),
    auth,
    orders,
    products
});