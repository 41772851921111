import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configureStore, { history, saveToLocalStorage } from './store';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import setAuthorizationToken from './utils/setAuthorizationToken';

const theme = {
    blue: '#00A3AD',
    yellow: '#FDCC08',
    red: '#DC2239',
    darkYellow: '#f6bd00',
    darkBlue: '#008a92',
    mediumMobile: '438px',
    notificationBackround: '#feefbc',
    notificationBorder: '#fcc63e',
    notificationColor: '#9a6f03',
    tablet: '768px',
    desktop: '1024px',
    // headerBackground: '#00A3AD',
    headerBackground: '#FFFFFF',
    headerLinkColor: '#424242',
    headerColor: '#424242',
    logoOptusColor: '#00A3AD',
    logoOptusPlatformColor: '#5f6062',
    bodyBackground: '#f3f2ed',
    bodyColor: '#424242',
    status: {
        awaiting_approval: {
            bg: '#CCEAFF',
            color: '#0E1B97'
        },
        pending: {
            bg: '#CCEAFF',
            color: '#0E1B97'
        },
        on_hold: {
            bg: '#FCF6A0',
            color: '#DA9800'
        },
        processing: {
            bg: '#FCF6A0',
            color: '#DA9800'
        },
        waiting_stock_status: {
            bg: '#FDCB77',
            color: '#EA6B38'
        },
        cancelled: {
            bg: '#FFC4CB',
            color: '#A51212'
        },
        approved: {
            bg: '#BAF07E',
            color: '#53841A'
        },
        shipped: {
            bg: '#DCFFB5',
            color: '#528716'
        }
    }
};

const store = configureStore(); 
const state = store.getState();
if (state.auth.jwt){
    setAuthorizationToken(state.auth.jwt)
}
store.subscribe(() => saveToLocalStorage(store.getState()));

ReactDOM.render(<Provider store={store}>
<ThemeProvider theme={theme}>
    <App history={history} />    
    </ThemeProvider>
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
