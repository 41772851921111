import React, { Component } from 'react';
import styled from 'styled-components';
import Page from '../components/page/Page';
import { Container } from '../components/page/Container';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import { history } from '../store';

const StylePage = styled.div`
    background: ${props => props.theme.blue };   
    min-height: 100vh;    
`;

export class LogoutPage extends Component {
    componentDidMount() {
        this.props.logout().then(() => {
            history.push('/');
        }).catch( error => {
            history.push('/');
        });
    }
    render() {        
        return (
            <StylePage>
                <Page>
                <Container>
                   We are logging you out
                </Container>
                </Page>
            </StylePage>        
        )
  }
}

function mapStateToProps(state) {
    return {
        
    }
}
export default connect(mapStateToProps, { logout })(LogoutPage)