import React from 'react';
import styled from 'styled-components';

const ErrorMessage = styled.div`
    background-color: white;
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid #750202;
    color: #750202;
    padding: 30px 15px;
    font-size: 12px;    
`;

export default ({ 
    children,
    type    
  }) => <ErrorMessage>{children}</ErrorMessage>;
