import React, { Component } from 'react';
import LoginForm from '../components/login/LoginForm';
import ForgottenPasswordForm from '../components/login/ForgottenPasswordForm';
import styled from 'styled-components';
//import { history } from '../store';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const StylePage = styled.div`
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${props => props.theme.mediumMobile}){
        padding: 50px 0;
        background: ${props => props.theme.blue };    
    }
    @media (min-width: ${props => props.theme.desktop}){
        padding: 50px 30px;
    }
`;

export class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
        recoverPassword: false
    }
    this.handleBack = this.handleBack.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }
  handleBack = (e) => {
    this.setState({ recoverPassword: false});
  };
  handleForgotPassword = (e) => {
    this.setState({ recoverPassword: true});
  };
  render() {
    return (
        <StylePage>
          {this.state.recoverPassword && <ForgottenPasswordForm buttonAction={this.handleBack}/> }
          {!this.state.recoverPassword && <LoginForm buttonAction={this.handleForgotPassword}/> }
      </StylePage>
    )
  }
}
LoginPage.propTypes = {
  auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
      auth: state.auth
  }
}
export default connect(mapStateToProps, null)(LoginPage);
