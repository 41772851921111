import { FETCH_PRODUCTS, FETCH_CATEGORIES,  UPDATE_SEARCH, NEW_PRODUCTS, DRAFT_PRODUCT } from './types';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let call = null;

export const fetchProducts = (page = 1, per_page = 10, cancelTokenSignal = null) => dispatch => {
    let cancelToken = cancelTokenSignal;
    if (!cancelTokenSignal) {
        cancelToken = CancelToken.source();
    }
    return axios.get(`/wp-json/wc/v3/products?per_page=${per_page}&page=${page}&order=asc&orderby=title&status=publish`,{
        cancelToken: cancelToken.token
    }).then(res => {
        const products = res.data;
        dispatch({
            type: FETCH_PRODUCTS,
            payload: {
                items: products,
                per_page,
                page,
                total_items: parseInt(res.headers['x-wp-total']),
                total_pages: parseInt(res.headers['x-wp-totalpages'])
            }
        });
        return res;
    }).catch( thrown => {
        if (axios.isCancel(thrown)) {
            return true;
        } else {
            // handle error
            throw thrown;
        }
    });
};
export const fetchCategories = (page = 1 , per_page = 10, cancelTokenSignal = null) => dispatch => {
    let cancelToken = cancelTokenSignal;
    if (!cancelTokenSignal) {
        cancelToken = CancelToken.source();
    }
    return axios.get(`/wp-json/wc/v3/categories?per_page=${per_page}&page=${page}&order=asc&orderby=name&hide_empty=true`,{
        cancelToken: cancelToken.token
    }).then(res => {
        const categories = res.data;
        dispatch({
            type: FETCH_CATEGORIES,
            payload: {
                categories,
                categories_page: page,
                total_categories: parseInt(res.headers['x-wp-total']),
                total_categories_pages: parseInt(res.headers['x-wp-totalpages'])
            }
        });
        return res;
    }).catch(function (thrown) {
        if (axios.isCancel(thrown)) {
            return true;
        } else {
            // handle error
            throw thrown;
        }
    });
}
export const stopFetchingProducts = () =>{
    if (call){
        call.cancel('Loging out');
    }
}
export function newProducts(products) {
    return dispatch => {
        dispatch({
            type: NEW_PRODUCTS,
            payload: products
        });
    }    
}
export function updateSearch(items, total_items) {
    return dispatch => {
        dispatch({
            type: UPDATE_SEARCH,
            payload: {
                search: items,
                total_items: total_items
            }
        });
    }
}
export function draftProducts(product) {
    return dispatch => {
        dispatch({
            type: DRAFT_PRODUCT,
            payload: product
        });
    }    
}