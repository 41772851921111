import axios from 'axios';
import setAuthorizationToken from '../utils/setAuthorizationToken';
import jwtDecode from 'jwt-decode';
import { SET_TOKEN, UPDATE_CURRENT_USER, LOGOUT } from './types';
import { stopFetchingProducts } from './products';

export function setToken(token){
    return {
        type: SET_TOKEN,
        token
    };
}
export function setCurrentStore(dealer_code){
    return {
        type: UPDATE_CURRENT_USER,
        payload: {
            current_store: dealer_code
        }
    };
}
export function logout() {
    stopFetchingProducts();
    return dispatch => {
        return axios.get('/wp-json/wc/v3/logout').then((res) => {
            localStorage.removeItem('state');
            setAuthorizationToken(false);
            dispatch({ type: LOGOUT });        
        }).catch(error => {
            console.error(error);
            localStorage.removeItem('state');
            setAuthorizationToken(false);
            dispatch({ type: LOGOUT });
        });
    }
}
export function login(data) {
    return async dispatch => {
        try {
            const res = await axios.post('/wp-json/jwt-auth/v1/token', data);
            const token = res.data.data.token;
            setAuthorizationToken(token);
            dispatch(setToken(token));
            console.log('the res', res)
            
            // const jwtData = jwtDecode(token);
            if (res.data.data.id) {
                await axios.get('/wp-json/wc/v3/login', {id: res.data.data.id});
                return true;
            }
            else {
                throw res;
            }
        }
        catch (error) {
            throw error;
        }
    }
}

export function resetPassword(data) {
    return async dispatch => {
        try {
            const res = await axios.post('/wp-json/wc/v3/customers/me/reset_password', data);
            return res;
        }
        catch (error) {
            throw error;
        }
    }
}
export function getUserData(){
    return dispatch => {
        // return axios.get('/wp-json/wp/v2/users/me?context=edit').then( (res) => {
        //     const { id, name, email, avatar_urls, roles, shipping } = res.data;
        //     dispatch({ type: UPDATE_CURRENT_USER, user: {
        //         name,
        //         email,
        //         avatar_urls,
        //         id,
        //         roles,
        //         shipping
        //     }, isAuthenticated: true });
        // }); 
        return axios.get('/wp-json/wc/v3/customers/me').then( (res) => {
            const { id, name, email, avatar_urls, roles, shipping, bp_code, dealer_code, billing, stores } = res.data;
            dispatch({ type: UPDATE_CURRENT_USER, payload: { user: {
                name,
                email,
                avatar_urls,
                id,
                roles,
                bp_code,
                dealer_code,
                shipping,
                billing,
                stores
            },current_store: dealer_code, isAuthenticated: true }});
        }); 
    }
}