import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { connect } from 'react-redux';
import Button from '../forms/Button';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { history } from '../../store';

const StyledHeaderUser = styled.div`
    display: flex;
    align-items: center;
    .user-picture {
        img {
            width: 45px;
            height: 45px;
            border-radius: 25px;
            background-color: white;
        }
    }
    .user-details {
        padding: 0;
        @media (min-width: 650px) {
            padding: 0 30px 0 15px;
        }
    }
    .user-details-content {
        visibility: hidden;
        position: absolute;
        top: 100%;
        right: 2px;
        transition: visibility 0s 0.3s linear, opacity 0.3s ease-out, transform 0.3s ease-out;
        transform: translateY(-4px);
        box-shadow: 0 10px 10px rgba(0,0,0,0.15);
        background: white;
        padding: 20px;
        opacity: 0;
        border-radius: 0 0 5px 5px;
        a {
            display: block;
            margin-bottom: 20px;
        }
        @media (min-width: 650px){
            display: flex;
            opacity: 1;
            align-items: center;
            background: none;
            padding: 0;
            position: static;
            visibility: visible;
            top: auto;
            right: auto;
            transform: translateY(0);
            box-shadow: none;
            a {
                display: block;
                margin-bottom: 0;
            }
        }
    }
    .user-picture {
        cursor: pointer;
        @media (min-width: 650px){
            cursor: default;
        }
    }
    .user-details-content--active {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;
        transition: visibility 0s 0s linear, opacity 0.3s ease-out, transform 0.3s ease-out;
    }
    .user-email {
        font-size: 12px;
    }
`;

export class HeaderUser extends Component {
    logout(e) {
        e.preventDefault();
        history.push('/logout');
    }
    handleClickUser() {
        document.querySelector('.user-details-content').classList.toggle('user-details-content--active');
    }
    render() {
        return (
        <StyledHeaderUser>
            <div className="user-picture" onClick={this.handleClickUser.bind(this)}>
            { this.props.auth.isAuthenticated && this.props.auth.user &&
                <img src={ this.props.auth.user && this.props.auth.user.avatar_urls['96'] } alt={ this.props.auth.user && this.props.auth.user.name } />
            }
            </div>
            <div className="user-details-content">
                <NavLink to="/profile">
                    <div className="user-details">
                        <div className="user-name">{ this.props.auth.user && this.props.auth.user.name } </div>
                        <div className="user-email">{ this.props.auth.user && this.props.auth.user.email }</div>
                    </div>
                </NavLink>
                <Button type="button" className="btn-primary btn-xs" onClick={this.logout.bind(this)}>Logout</Button>          
            </div>
        </StyledHeaderUser>
        )
    }
}

HeaderUser.propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    }
}
export default connect(mapStateToProps, { logout })(HeaderUser)
