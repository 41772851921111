import React, { Component } from 'react';
import styled from 'styled-components';

const StyledLoading = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255,255,255,0.6);
    z-index:10;
    svg{
        stroke: ${props => props.theme.blue };
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
`;

export class Loading extends Component {
    render() {
        const { isLoading } = this.props;
        if (isLoading) {
        return (
            <StyledLoading>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" stroke="#fff">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle cx="30.1931" cy="11.5314" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" values="50;5;50;50" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" begin="0s" dur="2.2s" values="5;27;49;5" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                            <circle cx="42.6137" cy="50" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" from="5" to="5" values="5;50;50;5" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" begin="0s" dur="2.2s" from="27" to="27" values="27;49;5;27" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                            <circle cx="8.19313" cy="43.4686" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" values="50;50;5;50" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" from="49" to="49" begin="0s" dur="2.2s" values="49;5;27;49" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                        </g>
                    </g>
                </svg>
            </StyledLoading>
            );
        } else {
            return "";
        }
    }
}
export default Loading;