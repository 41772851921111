import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
// import HomePage from '../pages/HomePage';
import ListOrders from '../pages/orders/ListOrders';
import OrdersPage from '../pages/OrdersPage';
import ViewOrder from '../pages/orders/ViewOrder';
import EditOrderPage from '../controllers/EditOrderPage';
import NewOrder from '../pages/orders/NewOrder';
import ProfilePage from '../pages/ProfilePage';
import LogoutPage from '../pages/LogoutPage';
import WordpressLogout from '../pages/WordpressLogout';
import requireAuth from '../utils/requireAuth';

const routes = (
  <div>
    <Switch>
      <Route path="/orders/view/:id" component={requireAuth(ViewOrder)} />
      <Route path="/orders/edit/:id" component={requireAuth(EditOrderPage)} />
      <Route path="/orders/new" component={requireAuth(NewOrder)} />
      <Route path="/orders" component={requireAuth(OrdersPage)} />      
      <Route path="/profile" component={ProfilePage} />
      <Route path="/logout" component={requireAuth(LogoutPage)} />
      <Route path="/my-account" component={WordpressLogout} />
      <Route path="/auth" component={LoginPage} />
      <Route exact path="/" component={requireAuth(ListOrders)} />      
    </Switch>
  </div>
)

export default routes;