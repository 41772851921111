import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword } from '../../actions/auth';
import Button from '../forms/Button';
import Input from '../forms/Input';
import styled from 'styled-components';
import Logo from '../../logo.svg';
import VitagLogo from '../../vitag-logo.png';

const StyledHeader = styled.div`
    border-bottom: 1px solid #efefef;
    padding: 10px 0 20px;
    margin-bottom: 20px;
    .logo-vitag__wrap {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #efefef;
        display: inline-block;
        img {
            max-width: 94px;
        }
    }
    .logo-optus-platform {
        max-width: 170px;
    }
    
`;

const StyledFooter = styled.div`
    margin-top: 30px;
    border-top: 1px solid #efefef;
    padding: 20px 0 0;    
    font-size: 0.9rem;
    a {
        font-weight: bold;
    }
`;
const StyleForm = styled.form`
    width: 100%;
    max-width: 420px;
    padding: 30px;
    margin: auto;
    background-color: white;
    position: relative;
    .loading {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(255,255,255,0.6);
        svg{
            stroke: ${props => props.theme.blue };
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
`;

export class ForgottenPasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            message: "",
            errors: {
                username: "",
            },
            isLoading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ errors: {username: ""
        }, isLoading: true });
        if (this.state.username.length > 3 ){
            this.props.resetPassword(this.state).then(
                res => { 
                    this.setState({ isLoading: false, message: "If you have an account, you'll receive an email shortly" });
                }
            ).catch( error => {
                console.log(error);
                const { code } = error.response.data;
                let errors = {
                    username: "You must enter a valid username"
                };
                switch (code){
                    case '[jwt_auth] invalid_username':
                        errors.username = 'Invalid username';
                    break;
                    default:
                    break;
                }
                this.setState({ errors, isLoading: false });
            });
        } else {
            this.setState({ errors: {
                username: "You must enter a valid username"
            }, isLoading: false });
        }
    }
    onChange = (e) => {
        e.preventDefault();
        const { name , value } = e.target;
        this.setState({ [name]: value, errors: {...this.state.errors, [name]: ""} });
    }
  render() {
    const { isLoading, errors } = this.state;
    return (
      <div>
        <StyleForm className="form" noValidate>
            <StyledHeader>
                <div className="logo-vitag__wrap">
                    <img src={VitagLogo} className="logo-vitag" alt="Vitag"/>
                </div>                
                <img src={Logo} className="logo-optus-platform" alt="Optus platform"/>
            </StyledHeader>
            <h2>Recover your password</h2>
            <p>Please enter your email.</p>
            { this.state.message && <div>{this.state.message}</div>}
            <Input id="username" error={errors.username } title="Email" type="email" className="form-control" name="username" placeholder="Username" onChange={(e) => this.onChange(e)} value={this.state.username}/>
            <Button type="submit" className="btn btn-primary btn-block" onClick={(e) => this.onSubmit(e) } disabled={isLoading}>Recover Password</Button>
            <StyledFooter>
                <Button onClick={() => this.props.buttonAction()} className="btn btn-secondary btn-block-xs">Back to Login</Button>
            </StyledFooter>
            { isLoading && (
            <div className="loading">
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" stroke="#fff">
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(1 1)" strokeWidth="2">
                            <circle cx="30.1931" cy="11.5314" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" values="50;5;50;50" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" begin="0s" dur="2.2s" values="5;27;49;5" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                            <circle cx="42.6137" cy="50" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" from="5" to="5" values="5;50;50;5" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" begin="0s" dur="2.2s" from="27" to="27" values="27;49;5;27" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                            <circle cx="8.19313" cy="43.4686" r="5">
                                <animate attributeName="cy" begin="0s" dur="2.2s" values="50;50;5;50" calcMode="linear" repeatCount="indefinite"/>
                                <animate attributeName="cx" from="49" to="49" begin="0s" dur="2.2s" values="49;5;27;49" calcMode="linear" repeatCount="indefinite"/>
                            </circle>
                        </g>
                    </g>
                </svg>
            </div>
            )}
        </StyleForm>
      </div>
    )
  }
}

ForgottenPasswordForm.propTypes = {
    resetPassword: PropTypes.func.isRequired
}

export default connect(null, { resetPassword } )(ForgottenPasswordForm);
