import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login, getUserData } from '../../actions/auth';
import Button from '../forms/Button';
import Input from '../forms/Input';
import styled from 'styled-components';
import Logo from '../../logo-vitag-optus-platform.svg';
import Loading from '../components/Loading';

const StyledHeader = styled.div`
    border-bottom: 1px solid #efefef;
    padding: 10px 0 20px;
    margin-bottom: 20px;
    .logo-vitag__wrap {
        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #efefef;
        display: inline-block;
        img {
            max-width: 94px;
        }
    }
    .logo-optus-platform {
        max-width: 300px;
    }
    
`;

const StyledFooter = styled.div`
    margin-top: 30px;
    border-top: 1px solid #efefef;
    padding: 20px 0 0;    
    font-size: 0.9rem;
    a {
        font-weight: bold;
    }
`;
const StyleForm = styled.form`
    width: 100%;
    max-width: 420px;
    padding: 30px;
    margin: auto;
    background-color: white;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);    
`;

export class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            errors: {
                username: "",
                password: ""
            },
            isLoading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onSubmit = (e) => {
        e.preventDefault();
        this.setState({ errors: {username: "",
        password: ""}, isLoading: true });
        if (this.state.username.length > 3 && this.state.password.length > 3 ){
            this.props.login(this.state).then(
                res => { 
                    this.props.getUserData().then( () => {
                        // history.push('/');
                    })
                }
            ).catch( error => {
                const { code } = error.response.data;
                let errors = {
                    username: "You must enter a valid username",
                    password: "You must enter a valid password"
                };
                switch (code){
                    case '[jwt_auth] invalid_username':
                        errors.username = 'Invalid username';
                    break;
                    case '[jwt_auth] incorrect_password':
                        errors.username = '';
                        errors.password = 'Incorrect password';
                    break;
                    default:
                    break;
                }
                this.setState({ errors, isLoading: false });
            });
        } else {
            this.setState({ errors: {
                username: "You must enter a valid username",
                password: "You must enter a valid password"
            }, isLoading: false });
        }
    }
    onChange = (e) => {
        e.preventDefault();
        const { name , value } = e.target;
        this.setState({ [name]: value, errors: {...this.state.errors, [name]: ""} });
    }
  render() {
    const { isLoading, errors } = this.state;
    return (
      <div>
        <StyleForm className="form" noValidate>
            <StyledHeader>
                <img src={Logo} className="logo-optus-platform" alt="Vitag Optus platform"/>
            </StyledHeader>
            <h2>Log in</h2>
            <p>Please log in using the email address you provided when you signed up.</p>
            <Input id="username" error={errors.username } title="Username" type="text" className="form-control" name="username" placeholder="Username" onChange={(e) => this.onChange(e)} value={this.state.username}/>
            <Input id="password" error={errors.password }  title="Password" type="password" className="form-control" name="password" placeholder="Password" onChange={(e) => this.onChange(e)} value={this.state.password}/>
            <Button type="submit" className="btn btn-primary btn-block" onClick={(e) => this.onSubmit(e) } disabled={isLoading}>Login</Button>
            <StyledFooter>
                <Button onClick={() => this.props.buttonAction() } className="btn-link">Forgot your username or password?</Button>
            </StyledFooter>
            <Loading isLoading={isLoading}/>
        </StyleForm>
      </div>
    )
  }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
    getUserData: PropTypes.func.isRequired
}

export default connect(null, { login, getUserData } )(LoginForm);
