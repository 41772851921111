import React, { Component } from 'react';
import Page from '../../components/page/Page';
import Header from '../../components/header/Header';
import Panel from '../../components/components/Panel';
import PanelSection from '../../components/components/PanelSection';
import PanelTotals from '../../components/components/PanelTotals';
import PropTypes from 'prop-types';
import { formatPrice } from '../../utils/helpers';
import { connect } from 'react-redux';
import { fetchOrders, loadOrder, newOrder, createDraftFromOrder } from '../../actions/orders';
import ProductList from '../../components/products/ProductList';
import Status from '../../components/components/Status';
import Button from '../../components/forms/Button';
import Link from '../../components/forms/Link';
import { history } from '../../store';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Breadcrumbs from '../../components/components/Breadcrumbs';
import styled from 'styled-components';

const StyledTwoColumns = styled.div`
    @media (min-width: ${props => props.theme.tablet}) {
        display: flex;
        margin: 0 -10px;
    }
`;
const StyledHalfColumn = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        flex-grow: 1;
    }
    @media (min-width: ${props => props.theme.tablet}) {
        width: 50%;
        padding: 0 10px;    
    }
`;

export class ViewOrder extends Component {
    constructor(props){
        super(props);
        this.handleReorder = this.handleReorder.bind(this);
    }
    componentDidMount() {
        const { id } = this.props.match.params;
        if (typeof this.props.orders[id] === "undefined"){
            this.props.loadOrder(id);
        }
    }
    handleReorder(event, order_id) {
        event.stopPropagation();
        this.props.createDraftFromOrder(order_id);
        history.push('/orders/new');
    }
    render() {
        const { id } = this.props.match.params;
        const { hideReOrder } = this.props;
        const order = this.props.orders[id];
        if (!order){
            return (
                <>
                    <Header/>
                    <Page>
                    <div>Loading</div>
                    </Page>
                </>
                );
        }
        const { invoice_number, tracking_provider, tracking_number, purchase_order_number, status, shipping, billing, payment_link, shipping_total, subtotal, total, total_tax, date_created, date_required_by, dealer_code, contact_details, customer_note, reason } = order;
        const products_list = order.line_items;
        
        const products = products_list.map( item => {
          return item;
        });
        const title = `Order #${id}`;
        let actions = '';
        if (!hideReOrder) {
            actions = (<>
                <Button type="button" className="btn btn-secondary" onClick={(e) => this.handleReorder(e,id)}>Re-order</Button>
            </>);
        }
        const roles = this.props.auth.user.roles;
        let is_company_owned_store = false;
        if (roles) {
            is_company_owned_store = (roles[0] === 'company_owned_store')? true: false;
        }
        const paynow = (() => {
            if (status === 'pending') {
                return (<>
                    <p>This order is pending payment</p><a href={payment_link} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Pay now</a>
                    </>);
            } else{
                return null;
            }
        })();
            
        return (
          <>
                <Header/>
                <Breadcrumbs>
                    <Link to="/">&lt; Back to orders</Link>                    
                </Breadcrumbs>
                <Page title={title} date={formatDate(date_created, 'dddd, MMMM Do YYYY, h:mm:ss a')} titleExtras={<Status type={status}>{status}</Status>} actions={actions}>
                    { !is_company_owned_store && paynow !== null && <Panel notice>{ paynow }</Panel>}
                    <div className="order">
                        <div className="row align-items-start">
                            <div className="col-lg-3 order-lg-last">
                                <Panel noPadding>
                                    <div className="container-fluid">
                                        <div className="row">
                                            { invoice_number && (<PanelSection title="Invoice number" className="col-sm-6 col-lg-12">
                                            <div>{invoice_number}</div>
                                            </PanelSection>)}
                                            { tracking_provider !== undefined && tracking_provider !== '' && (<PanelSection title="Tracking provider" className="col-sm-6 col-lg-12">
                                            <div>{ tracking_provider }</div>
                                            </PanelSection>)}  
                                            { tracking_number !== undefined && tracking_number !== '' && (<PanelSection title="Tracking" className="col-sm-6 col-lg-12">
                                                <div>{tracking_number}</div>
                                            </PanelSection>)}                                            
                                            { purchase_order_number && (<PanelSection title="Optus purchase order number" className="col-sm-6 col-lg-12">
                                            <div>{purchase_order_number}</div>
                                            </PanelSection>)}
                                            { dealer_code && (<>
                                                <PanelSection title="Dealer Code" className="col-12">
                                                    <div>{ dealer_code }</div>
                                                </PanelSection>
                                                </>
                                            )}
                                            <PanelSection title="Required by" className="col-sm-6 col-lg-12">
                                            <div>{formatDate(parseDate(date_required_by, 'D/M/YYYY', 'au'), 'dddd, MMMM Do YYYY')}</div>
                                            </PanelSection>
                                            <PanelSection title="Contact details" className="col-sm-6 col-lg-12">
                                                <div>{contact_details}</div>
                                            </PanelSection> 
                                            { reason !== '' && <PanelSection title="Reason for request" className="col-sm-6 col-lg-12">
                                                <div>{reason}</div>
                                            </PanelSection>}
                                            <PanelSection title="Note" noBorder className="col-sm-6 col-lg-12">
                                                <div>{customer_note ? customer_note: '-' }</div>
                                            </PanelSection>                                               
                                        </div>
                                    </div>
                                </Panel>
                            </div>
                            <div className="col-lg-9 order-lg-first">
                                <StyledTwoColumns>
                                    <StyledHalfColumn>
                                        <Panel noPadding>
                                            <PanelSection title="Billing" noBorder>
                                                { billing && (<>
                                                    { billing.company && ( <>{ billing.company }<br/></> )}{ billing.first_name } { billing.last_name }<br/>
                                                    { billing.address_1}{ billing.address_2 && (<> {billing.address_2}</>)}, {billing.city} {billing.state} {billing.postcode}
                                                </>)}
                                            </PanelSection>
                                        </Panel>
                                    </StyledHalfColumn>
                                    <StyledHalfColumn>
                                        <Panel noPadding>
                                            <PanelSection title="Shipping" noBorder>
                                                { shipping && (<>
                                                    { shipping.company && ( <>{ shipping.company }<br/></> )}{ shipping.first_name } { shipping.last_name }<br/>
                                                    { shipping.address_1}{ shipping.address_2 && (<> {shipping.address_2}</>)}, {shipping.city} {shipping.state} {shipping.postcode}
                                                </>)}
                                            </PanelSection>
                                        </Panel>
                                    </StyledHalfColumn>
                                </StyledTwoColumns>
                                <Panel paddingTop classNames="overflow-hidden">
                                    <ProductList isLoading={false} products={products} showTotal isReadOnly={true} />
                                </Panel>
                                <PanelTotals>
                                    <table> 
                                        <thead>
                                            <tr>
                                                <th>Subtotal: </th><td className="order__total">{formatPrice(subtotal)}</td>
                                            </tr>
                                            { shipping_total > 0 &&
                                                <tr>
                                                    <th>Shipping: </th><td className="order__total">{formatPrice(shipping_total)}</td>
                                                </tr>
                                            }
                                            <tr>
                                                <th>GST: </th><td className="order__total">{formatPrice(total_tax)}</td>
                                            </tr>
                                            <tr>
                                                <th>Total: </th><td className="order__total">{formatPrice(total)}</td>
                                            </tr>
                                        </thead>
                                    </table> 
                                </PanelTotals>                                
                            </div>
                        </div>
                    </div>                    
                </Page>
            </>       
        )
    }
}

ViewOrder.propTypes = {
    fetchOrders: PropTypes.func.isRequired,
    loadOrder: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    orders: PropTypes.object.isRequired,
    products: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
        auth: state.auth,
        products: state.products,
        orders: state.orders.items,
        order: state.orders.item
});

export default connect(mapStateToProps, { fetchOrders, loadOrder, newOrder, createDraftFromOrder })(ViewOrder)
