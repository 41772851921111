import React, { Component } from 'react'
import Status from '../components/Status';
import Button from '../../components/forms/Button';
import styled from 'styled-components';
import { formatPrice } from '../../utils/helpers';

const StyledTr = styled.tr`
  cursor: pointer;
  font-size: 0.875rem;
  .column-number {
      text-align: right;
  }
`;

export class OrderListItem extends Component {
    render() {
    const { id, total, date_created, status, line_items, purchase_order_number, invoice_number, dealer_code, shipping } = this.props.order;
    const items = line_items.reduce( (acumulator, item) => {
        return acumulator + item.quantity
    },0);
    return (
        <StyledTr onClick={ () => this.props.handleClick(id) } key={id}>
            <td>{ id }</td>
            <td><Status type={ status }>{ status }</Status></td>
            <td>{ typeof invoice_number !== 'undefined' && invoice_number }</td>
            { this.props.showCustomerDetails && 
                <>
                <td>{ shipping.company }</td>
                <td>{ dealer_code }</td>
                </>
            }
            { this.props.showPurchaseOrderNumber && 
                <td>{purchase_order_number}</td>
            }
            <td>{ Intl.DateTimeFormat('en-AU', { 
                year: 'numeric', 
                month: 'long', 
                day: '2-digit' 
            }).format(Date.parse(date_created)) }
            </td>
            <td className="column-number">{items}</td>
            { this.props.buttonAction && this.props.showReorder && <td> <Button type="button" className="btn btn-secondary" onClick={(e) => this.props.buttonAction(e,id)}>{this.props.buttonName}</Button></td>}
            <td className="column-number">{ formatPrice(total) } <small>inc. GST</small></td>
        </StyledTr>
    )
  }
}

export default OrderListItem
