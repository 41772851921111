import React, { PureComponent } from "react";
import ProductItem from './ProductItem';

export default class ProductRow extends PureComponent {
    constructor() {
        super();
        this.updateDimensions = this.updateDimensions.bind(this);
    }
    componentDidMount = () => {
        window.addEventListener("resize", this.updateDimensions);        
    };
    updateDimensions = event => {
        this.props.data.getChildItemSize(this.props.index, this.ProductItem.offsetHeight);        
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }
    setProductItemRef = element => {
        this.ProductItem = element;
        if (element && element.offsetHeight) {
            setTimeout( () => {
                this.props.data.getChildItemSize(this.props.index, element.offsetHeight);
            },100);
        }
    }
    render() {
        const { index, style, data } = this.props;
        const product = data.products[index];
        const id = product.product_id ? product.product_id: product.id;
        const quantity = product.quantity ? product.quantity: "";
        return (
            <div style={style} key={id}>
                <ProductItem ref={ this.setProductItemRef } key={id} index={index} style={style} product={product} showProductImage={data.showProductImage} color={data.color} defaultValue={quantity} showQuantityInput updateProduct={data.updateProductInOrder}/>
            </div>
        );
    }
}