import React from 'react';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StyledLabel = styled.label`
    color: black;
    font-weight: bold;
    font-size: 0.8rem;    
`;
const StyledInput = styled.input`
    color: black;
    &.form-control {
        border-radius: 0;
    }
`;
const StyledTextArea = styled.textarea`
    color: black;
    &.form-control {
        border-radius: 0;
    }
`;
const Input = (props) => {
  return (  
  <div className="form-group">
    <StyledLabel htmlFor={props.name} className="form-label">{props.title} {
        props.tooltip &&
      (
        <OverlayTrigger
          key='right'
          placement={ props.tooltipPlacement ? props.tooltipPlacement : 'right'}
          overlay={
            <Tooltip id={`tooltip-right`}>
              {props.tooltip}
            </Tooltip>
          }
        >
          <i className="far fa-question-circle"></i>
        </OverlayTrigger>
      )
    }</StyledLabel>
    { props.type === 'textarea' ? (
      <StyledTextArea
      className={
        props.error ? 'form-control is-invalid': 'form-control' }
      id={props.name}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      rows={props.rows}
    />
    ): (
      <StyledInput
      className={
        props.error ? 'form-control is-invalid': 'form-control' }
      id={props.name}
      name={props.name}
      type={props.type}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder} 
    />
    )}
    {
        props.error && (
            <div className="invalid-feedback">
            { props.error }
            </div>
        )
    }
  </div>
)
}

export default Input;