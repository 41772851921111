import React, { Component } from 'react';
import { fetchStatuses } from '../../actions/orders';
//import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';

// const StyledSelect = styled.select`
//   white-space: nowrap;
// `;

class OrderStatusesDropdown extends Component {
    componentDidMount() {
        this.props.fetchStatuses();
    }
    render() {
        const { statuses, selected, onlyShow } = this.props;
        console.log(statuses)
        if (statuses) {
            let options = Object.keys(statuses).map( status => {
                return { value: status, label: statuses[status]};
            });
            if (onlyShow && onlyShow.length > 0){
                options = options.filter( option => {
                    return onlyShow.includes(option.value);
                });
            }
            return (
                <div>
                    <Select options={options} onChange={this.props.handleStatusChange } value={{ label: statuses[selected], value: selected}} isClearable placeholder="Filter by Status"/>                               
                </div>
            )
        } else{
            return null;
        }
    }
}
OrderStatusesDropdown.propTypes = {
    fetchStatuses: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
    return {
        statuses: state.orders.statuses
    }
}
export default connect(mapStateToProps, { fetchStatuses })(OrderStatusesDropdown)