import { FETCH_ORDERS, UPDATE_LOADING, FETCH_PRODUCTS_FROM_ORDER, RECEIVE_ORDERS_PAGE, NEW_ORDER, LOAD_ORDER_TO_DRAFT, LOAD_ORDER_TO_EDIT, FETCH_STATUSES, CLEAR_DRAFT_ORDER, UPDATE_DRAFT_FROM_ORDER, UPDATE_DRAFT_ORDER, SET_EDIT_ORDER } from './types';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let call = null;

export const fetchOrders =  (cancelTokenSignal = null, page = 1, per_page = 10, search = null, status = null) => dispatch => {
    try {
        let cancelToken = cancelTokenSignal;
        if (!cancelTokenSignal) {
            cancelToken = CancelToken.source();
        }
        dispatch({
          type: UPDATE_LOADING,
          payload: {
            is_loading: true
          }
        })
        
        return axios.get(`/wp-json/optus/v1/orders?per_page=${per_page}&page=${page}&s=${search}&status=${status}`,{
            cancelToken: cancelToken.token
        }).then(res => {
            dispatch({
                type: FETCH_ORDERS,
                payload: {
                    items: res.data,
                    per_page,
                    is_loading: false,
                    page,
                    total_items: parseInt(res.headers['x-wp-total']),
                    total_pages: parseInt(res.headers['x-wp-totalpages'])
                }
            });
            dispatch({
                type: RECEIVE_ORDERS_PAGE,
                payload: {
                    pages: parseInt(res.headers['x-wp-totalpages']),
                    page: page,
                    items: res.data
                }
            });
        }).catch( thrown => {
            if (axios.isCancel(thrown)) {
                return 'cancelled';
            } else {
                throw thrown;
            }
        });
    } catch (err) {
        if (axios.isCancel(err)) {
            return 'cancelled';
        } else {
            throw err
        }
    }
};
export const fetchStatuses = () => dispatch => {
    call = CancelToken.source();
    return axios.get(`/wp-json/wc/v3/order_statuses`,{
        cancelToken: call.token
    }).then(res => {
        dispatch({
            type: FETCH_STATUSES,
            payload: res.data
        })
    });
};
export const loadOrder = (id, cancelTokenSignal = null) => dispatch => {
    let cancelToken = cancelTokenSignal;
    if (!cancelTokenSignal) {
        cancelToken = CancelToken.source();
    }
    return axios.get(`/wp-json/optus/v1/orders?id=${id}`, {
        cancelToken: cancelToken.token
    }).then(res => {
        dispatch({
            type: FETCH_ORDERS,
            payload: {
                items: res.data
            }
        })
    }).catch( thrown => {
        if (axios.isCancel(thrown)) {
            return true;
        } else {
            throw thrown;
        }
    });
};
export function newOrder(order) {
    return dispatch => {
        dispatch({
            type: NEW_ORDER,
            payload: order
        });
    }    
}
export function submitOrder(order, customer) {
    return dispatch => {
        const products = order.products.map( item =>{
            return {
                product_id: item.product_id,
                quantity: item.quantity
            }
        });
        const meta_data = [
            {
                key: 'order_approver',
                value: order.order_approver
            },
            {
                key: 'contact_details',
                value: order.contact_details
            },
            {
                key: 'date_required_by',
                value: order.date_required_by
            },
            {
                key: 'bp_code',
                value: order.bp_code
            },
            {
                key: 'dealer_code',
                value: order.dealer_code
            },
            {
                key: 'reason',
                value: order.reason
            },
            {
                key: 'purchase_order_number',
                value: order.purchase_order_number
            },
            {
                key: 'wbs_code',
                value: order.wbs_code
            },
            {
                key: 'cost_centre',
                value: order.cost_centre
            }
        ];
        var data = {
            set_paid: false,
            status: order.status,
            customer_id: customer,
            customer_note: order.customer_note,
            billing: {
                ...order.billing
            },
            shipping: {
                ...order.shipping
            },
            meta_data,
            line_items: [
                ...products
            ],
            shipping_lines: order.shipping_lines
        };
        if (!data.billing.email) {
            data.billing.email = customer.email;
        }
        return axios.post(`/wp-json/wc/v3/orders`, data).then(async res => {
            await axios.post(`/wp-json/wc/v3/customers/me/send_notification?order=${res.data.id}`, {id: res.data.id}).then(res => {
                
            });
            return res;
        }).catch( thrown => {
            if (axios.isCancel(thrown)) {
                return true;
            } else {
                throw thrown;
            }
        });
    }
}
export function updateOrder(originalOrder, order, shipping, customer, newStatus) {
    return dispatch => {
        let different = false;
        if (originalOrder.line_items.length !== order.products.length){
            different = true;
        }
        //Get all products to update or add
        let products = order.products.map( item =>{
            if (!different) {
                if (!item.id) {
                    different = true;
                } else {
                    const found = originalOrder.line_items.find( line_item => {
                        return line_item.id === item.id
                    });
                    if (!found || (found && found.quantity !== item.quantity)) {
                        different = true;
                    }
                }                
            }
            if( item.id ) {
                if (item.quantity > 0){
                    return {
                        id: item.id,
                        product_id: item.product_id,
                        price: parseFloat(item.price) / 1.1,
                        quantity: item.quantity
                    }
                } else {
                    return {
                        id: item.id,
                        quantity: 0,
                        product_id: null,
                        method_id: null,
                        title: null,
                        code: null
                    }
                }
            } else {
                if (item.quantity > 0) {
                    return {
                        product_id: item.product_id,
                        quantity: item.quantity
                    }
                } else {
                    return null;
                }
            }
        });
        const productsToDelete =[];
        //Get the products to delete
        originalOrder.line_items.forEach( product => {
            const found = products.find( prod => {
                if (prod.product_id === product.product_id) {
                    prod.id = product.id;
                    return true;
                }
                return false;
            });
            if (!found){
                different = true;
                productsToDelete.push(product);
            }
        });
        //Add products to delete into the products object with quantity 0
        if (productsToDelete.length > 0){
            productsToDelete.forEach( product => {
                products.push({
                    id: product.id,
                    quantity: 0,
                    product_id: null,
                    method_id: null,
                    title: null,
                    code: null
                });
            })
        }
        
        const meta_data = {
                order_approver: order.order_approver,
                contact_details: order.contact_details,
                date_required_by: order.date_required_by,
                bp_code: order.bp_code,
                dealer_code: order.dealer_code,
                reason: order.reason,
                notes: order.notes,
                purchase_order_number: order.purchase_order_number,
                wbs_code: order.wbs_code,
                cost_centre: order.cost_centre,
                cancelled_note: order.cancelled_note,
                order_edited_by_profit_protection: different ? 1: 0
        };
        let status = order.status;
        if (newStatus !== order.status){
            status = newStatus;
        }
        const data = {
            order: {
                status,
                order_meta: meta_data,
                line_items: [
                    ...products
                ]
            }            
        };
        if (order.note){
            data.order.note = order.note;
        }
        if (order.shipping_lines) {
            data.order.shipping_lines = order.shipping_lines;
        }
        return axios.put(`/wp-json/optus/v1/orders/update/${originalOrder.id}`, data).then(async res => {
            dispatch({
                type: FETCH_ORDERS,
                payload: {
                    items: [res.data]
                }
            });
            dispatch({
                type: FETCH_PRODUCTS_FROM_ORDER,
                payload: {
                    items: [res.data]
                }
            });
            return res;
        }).catch( thrown => {
            if (axios.isCancel(thrown)) {
                return true;
            } else {
                throw thrown
            }
        });
    }
}
export function createDraftFromOrder(order_id){
    return dispatch => {
        dispatch({
            type: UPDATE_DRAFT_FROM_ORDER,
            payload: {
                order_id
            }
        });
    }
}
export function loadOrderToDraft(order_id){
    return dispatch => {
        dispatch({
            type: LOAD_ORDER_TO_DRAFT,
            payload: {
                order_id
            }
        });
    }
}
export function loadOrderToEdit(order_id){
    return dispatch => {
        dispatch({
            type: LOAD_ORDER_TO_EDIT,
            payload: {
                order_id
            }
        });
    }
}
export function updateDraftOrder(order) {
    return dispatch => {
        dispatch({
            type: UPDATE_DRAFT_ORDER,
            payload: order
        });
    }    
}
export function updateEditOrder(order) {
    return dispatch => {
        dispatch({
            type: SET_EDIT_ORDER,
            payload: order
        });
    }    
}
export const stopFetchingOrders = () =>{
    if (call){
        call.cancel('Loging out');
    }
}
export function clearDraftOrder() {
    return dispatch => {
        dispatch({
            type: CLEAR_DRAFT_ORDER
        });
    }    
}