import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadOrder, loadOrderToDraft } from '../actions/orders';
import PropTypes from 'prop-types';
import EditOrder from '../pages/orders/EditOrder';
import ViewOrder from '../pages/orders/ViewOrder';
import axios from 'axios';

export class EditOrderPage extends Component {
    isActive = true;
    cancelOrderTokenSignal = axios.CancelToken.source();
    componentDidMount() {
        this.isActive = true;
        const { id } = this.props.match.params;
        if(typeof this.props.orders[id] === "undefined") {
            this.props.loadOrder(id, this.cancelOrderTokenSignal);
        }
    }
    componentWillUnmount() {
        this.isActive = false;
        this.cancelOrderTokenSignal.cancel('Api Order is being canceled');        
    }
    render() {
        const { id } = this.props.match.params;
        const { status } = this.props.orders[id];
        if (this.isActive ) {
            if (status === 'awaiting-approval' || status === 'pending'){
                return <EditOrder match={ { params: { id } } }/>;
            } else {
                return <ViewOrder match={ { params: { id } } }  hideReOrder={true}/>;                
            }
        }
        return false;
    }
}

EditOrderPage.propTypes = {
    loadOrderToDraft: PropTypes.func.isRequired, 
    loadOrder: PropTypes.func.isRequired,
    orders: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        draft_order: state.orders.draft,
        orders: state.orders.items
    }
}
export default connect(mapStateToProps, { loadOrderToDraft, loadOrder })(EditOrderPage);
